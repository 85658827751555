import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DetailRateSchedule, ReportingService } from '@gms/reporting-api';
import { TelerikReportServerService } from 'app/store/reports/telerik-report-server.service';
import {
  ClientDownloadPdfFailure,
  ClientDownloadPdfSuccess,
  DownloadPdf,
  DownloadPdfFailure,
  DownloadPdfSuccess,
  EReportsActions,
  FetchReportClientId,
  FetchReportClientIdError,
  FetchReportClientIdSuccess,
  FetchReportDefinitionById,
  FetchReportDefinitionByIdError,
  FetchReportDefinitionByIdSuccess,
  FetchReportDetailRateSchedule,
  FetchReportDetailRateScheduleError,
  FetchReportDetailRateScheduleSuccess,
  FetchReportParameters,
  FetchReportParametersError,
  FetchReportParametersSuccess,
  SetReportParameterValues,
  SetReportParameterValuesSuccess,
} from './reports.actions';

@Injectable()
export class ReportsEffects {
  constructor(
    private _actions$: Actions,
    private _reportingService: ReportingService,
    private _telerikReportServerService: TelerikReportServerService
  ) {}

  FetchReportDefinitionById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchReportDefinitionById>(EReportsActions.FETCH_REPORT_DEFINITION_BY_ID),
      map(action => action.payload),
      switchMap(payload => {
        return this._reportingService.getReportDefinitionById(payload.reportId).pipe(
          map(reportDefinition => {
            return new FetchReportDefinitionByIdSuccess({ reportDefinition });
          }),
          catchError(error => of(new FetchReportDefinitionByIdError({ error: error })))
        );
      })
    )
  );

  FetchReportClientId$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchReportClientId>(EReportsActions.FETCH_REPORT_CLIENT_ID),
      switchMap(() => {
        return this._telerikReportServerService.getReportClientId().pipe(
          map(clientId => {
            return new FetchReportClientIdSuccess({ clientId });
          }),
          catchError(error => of(new FetchReportClientIdError({ error: error })))
        );
      })
    )
  );

  FetchReportParameters$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchReportParameters>(EReportsActions.FETCH_REPORT_PARAMETERS),
      map(action => action.payload),
      switchMap(payload => {
        const clientId = payload.clientId;
        const reportName = payload.reportName;
        return this._telerikReportServerService.getReportParameters(reportName, clientId).pipe(
          map(parameterValues => {
            return new FetchReportParametersSuccess({ parameterValues });
          }),
          catchError(error => of(new FetchReportParametersError({ error: error })))
        );
      })
    )
  );

  SetReportParameterValues$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SetReportParameterValues>(EReportsActions.SET_REPORT_PARAMETER_VALUES),
      map(action => action.payload),
      switchMap(payload => {
        return of(new SetReportParameterValuesSuccess(payload));
      })
    )
  );

  DownloadPdf$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DownloadPdf>(EReportsActions.DOWNLOAD_PDF),
      map(action => action.payload),
      switchMap(payload => {
        return this._telerikReportServerService.triggerPdfDownload(payload.url).pipe(
          map(blob => {
            return new DownloadPdfSuccess({
              pdfBlob: blob,
              pdfName: payload.pdfName,
              fileExtension: payload.fileExtension,
            });
          }),
          catchError(error => of(new DownloadPdfFailure({ error })))
        );
      })
    )
  );

  ClientDownloadPdf$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DownloadPdfSuccess>(EReportsActions.DOWNLOAD_PDF_SUCCESS),
      map(action => action.payload),
      map(({ pdfBlob, pdfName, fileExtension }) => {
        // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api

        const extension = fileExtension.toLowerCase();

        const newBlob = new Blob([pdfBlob], { type: `application/${extension}` });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(newBlob);
        //   return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = `${pdfName}.${extension}`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
        );

        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
        return new ClientDownloadPdfSuccess();
      }),
      catchError(error => of(new ClientDownloadPdfFailure({ error })))
    )
  );

  FetchReportDetailRateSchedule: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchReportDetailRateSchedule>(EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE),
    map(action => action.payload),
    switchMap(payload => {
      return this._reportingService.getDetailRateSchedule(payload.tspId).pipe(
        map((detailRateSchedule: DetailRateSchedule) => {
          return new FetchReportDetailRateScheduleSuccess(detailRateSchedule);
        }),
        catchError(error => of(new FetchReportDetailRateScheduleError({ error: error })))
      );
    })
  ));
}
