import {
  clearState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from 'app/store/app/app.models';
import { ESchedulingActions, SchedulingActions } from 'app/store/scheduling/scheduling.actions';
import { initialSchedulingState, ISchedulingState } from 'app/store/scheduling/scheduling.state';
import get from 'lodash/get';

export function schedulingReducers(
  state = initialSchedulingState,
  action: SchedulingActions
): ISchedulingState {
  switch (action.type) {
    // --------------------------------------------------
    // RUN RESULTS
    // --------------------------------------------------
    case ESchedulingActions.FETCH_RUN_RESULTS:
      return {
        ...state,
        runs: [],
        totalRunsCount: 0,
        runsError: null,
        runsLoading: true,
        userInitiated: action.userInitiated,
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_SUCCESS:
      return {
        ...state,
        runs: action.payload.runResults,
        totalRunsCount: action.payload.total,
        runsError: null,
        runsLoading: false,
        userInitiated: false,
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_ERROR:
      return {
        ...state,
        runs: [],
        totalRunsCount: 0,
        runsError: action.payload.error,
        runsLoading: false,
        userInitiated: false,
      };

    case ESchedulingActions.FETCH_RUN_RESULTS_RESET:
      return {
        ...state,
        runs: [],
        totalRunsCount: 0,
        runsError: null,
        runsLoading: false,
      };

    // --------------------------------------------------
    // PRIORITY OF SERVICE RESULTS
    // --------------------------------------------------
    case ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS:
      return {
        ...state,
        priorityOfServiceCollection: createLoadingState(state.priorityOfServiceCollection),
      };
    case ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS_SUCCESS:
      return {
        ...state,
        priorityOfServiceCollection: createSuccessState(action.payload),
      };
    case ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS_FAILURE:
      return {
        ...state,
        priorityOfServiceCollection: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // RUN RESULTS VALIDATION LOOKUP
    // --------------------------------------------------
    case ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP:
      return {
        ...state,
        runsForValidationLookup: createLoadingState(state.runsForValidationLookup),
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP_SUCCESS:
      return {
        ...state,
        runsForValidationLookup: createSuccessState(action.payload),
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP_ERROR:
      return {
        ...state,
        runsForValidationLookup: createErrorState(action.payload.error),
      };
    case ESchedulingActions.CLEAR_RUN_RESULTS_VALIDATION_LOOKUP:
      return {
        ...state,
        runsForValidationLookup: clearState(state.runsForValidationLookup),
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE:
      return {
        ...state,
        runsForNAESBCycle: createLoadingState(state.runsForNAESBCycle),
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE_SUCCESS:
      return {
        ...state,
        runsForNAESBCycle: createSuccessState(action.payload),
      };
    case ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE_ERROR:
      return {
        ...state,
        runsForNAESBCycle: createErrorState(action.payload.error),
      };
    case ESchedulingActions.CLEAR_RUN_RESULTS_NAESB_CYCLE:
      return {
        ...state,
        runsForNAESBCycle: clearState(state.runsForNAESBCycle),
      };

    // --------------------------------------------------
    // RUN SCHEDULING ENGINE
    // --------------------------------------------------
    case ESchedulingActions.RUN_SCHEDULING_ENGINE:
      return {
        ...state,
        runSchedulingEngineError: null,
        runSchedulingEngineLoading: true,
        runSchedulingEngineSuccess: false,
      };
    case ESchedulingActions.RUN_SCHEDULING_ENGINE_SUCCESS:
      return {
        ...state,
        runSchedulingEngineError: null,
        runSchedulingEngineLoading: false,
        runSchedulingEngineSuccess: true,
      };
    case ESchedulingActions.RUN_SCHEDULING_ENGINE_ERROR:
      return {
        ...state,
        runSchedulingEngineError: action.payload.error,
        runSchedulingEngineLoading: false,
        runSchedulingEngineSuccess: false,
      };
    case ESchedulingActions.RUN_SCHEDULING_ENGINE_RESET:
      return {
        ...state,
        runSchedulingEngineError: null,
        runSchedulingEngineLoading: false,
        runSchedulingEngineSuccess: false,
      };

    // --------------------------------------------------
    // SCHEDULING ENGINE TEMPLATE
    // --------------------------------------------------
    case ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE:
      return {
        ...state,
        Template: null,
        TemplateLoading: true,
        TemplateError: null,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_SUCCESS:
      return {
        ...state,
        Template: action.payload,
        TemplateLoading: false,
        TemplateError: null,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_ERROR:
      return {
        ...state,
        Template: null,
        TemplateLoading: false,
        TemplateError: action.payload.error,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_RESET:
      return {
        ...state,
        Template: null,
        TemplateLoading: false,
        TemplateError: null,
      };

    // --------------------------------------------------
    // RUN RESULTS NOM DETAIL
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL:
      return {
        ...state,
        runResultsNomDetail: createLoadingState(state.runResultsNomDetail),
      };
    case ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL_SUCCESS:
      return {
        ...state,
        runResultsNomDetail: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL_ERROR:
      return {
        ...state,
        runResultsNomDetail: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // RUN RESULTS P/L COMPONENT
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT:
      return {
        ...state,
        runResultsPLComponent: createLoadingState(state.runResultsPLComponent),
      };
    case ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT_SUCCESS:
      return {
        ...state,
        runResultsPLComponent: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT_ERROR:
      return {
        ...state,
        runResultsPLComponent: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // EXPORT RUN RESULTS P/L COMPONENT
    // --------------------------------------------------
    case ESchedulingActions.EXPORT_PL_COMPONENT:
      return {
        ...state,
        exportPLComponent: createLoadingState(state.exportPLComponent),
      };

    case ESchedulingActions.EXPORT_PL_COMPONENT_SUCCESS:
      return {
        ...state,
        exportPLComponent: createSuccessState(null),
      };

    case ESchedulingActions.EXPORT_PL_COMPONENT_ERROR:
      return {
        ...state,
        exportPLComponent: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // RUN RESULTS OVERVIEW
    // --------------------------------------------------
    case ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW:
      return {
        ...state,
        runResultsOverview: null,
        runResultsOverviewError: null,
        runResultsOverviewLoading: true,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        runResultsOverview: action.payload,
        runResultsOverviewError: null,
        runResultsOverviewLoading: false,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_ERROR:
      return {
        ...state,
        runResultsOverview: null,
        runResultsOverviewError: action.payload.error,
        runResultsOverviewLoading: false,
      };
    case ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_RESET:
      return {
        ...state,
        runResultsOverview: null,
        runResultsOverviewError: null,
        runResultsOverviewLoading: false,
      };
    // --------------------------------------------------
    // RUN RESULTS K-PATH LIMITS
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS:
      return {
        ...state,
        runResultsKPathLimits: createLoadingState(state.runResultsKPathLimits),
      };
    case ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS_SUCCESS:
      return {
        ...state,
        runResultsKPathLimits: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS_ERROR:
      return {
        ...state,
        runResultsKPathLimits: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // SCHEDULING PAGE INFORMATION
    // --------------------------------------------------
    case ESchedulingActions.UPDATE_SCHEDULING_PAGE_INFORMATION:
      return {
        ...state,
        SchedulingPageInformation: {
          runResults: action.payload.runResults,
          actionBar: get(action, 'payload.actionBar.value', {}),
        },
      };

    // --------------------------------------------------
    // SCHEDULING ENGINE CONTRACT TAB, CHAIN PATH LIMIT SEGMENT
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS:
      return {
        ...state,
        runResultsChainPathLimits: createLoadingState(state.runResultsChainPathLimits),
      };
    case ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS_SUCCESS:
      return {
        ...state,
        runResultsChainPathLimits: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS_ERROR:
      return {
        ...state,
        runResultsChainPathLimits: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // CREATE SCHEDULING ENGINE OVERRIDE
    // --------------------------------------------------
    case ESchedulingActions.CREATE_SE_OVERRIDE:
      return {
        ...state,
        createSeOverride: createLoadingState(state.createSeOverride),
      };
    case ESchedulingActions.CREATE_SE_OVERRIDE_SUCCESS:
      return {
        ...state,
        createSeOverride: createSuccessState(action.payload),
      };
    case ESchedulingActions.CREATE_SE_OVERRIDE_ERROR:
      return {
        ...state,
        createSeOverride: createErrorState(action.payload.error),
      };
    case ESchedulingActions.CLEAR_SE_OVERRIDE:
      return {
        ...state,
        createSeOverride: clearState(state.createSeOverride),
      };

    // --------------------------------------------------
    // SCHEDULING ENGINE CONTRACT TAB, MAX PATH LIMIT SEGMENT
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS:
      return {
        ...state,
        runResultsMaxAvailablePathLimits: createLoadingState(
          state.runResultsMaxAvailablePathLimits
        ),
      };
    case ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS_SUCCESS:
      return {
        ...state,
        runResultsMaxAvailablePathLimits: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS_ERROR:
      return {
        ...state,
        runResultsMaxAvailablePathLimits: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // SCHEDULING ENGINE Run Results Commit
    // --------------------------------------------------
    case ESchedulingActions.GET_RUN_RESULTS_COMMIT:
      return {
        ...state,
        runResultsCommit: createLoadingState(state.runResultsCommit),
      };
    case ESchedulingActions.GET_RUN_RESULTS_COMMIT_SUCCESS:
      return {
        ...state,
        runResultsCommit: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_RUN_RESULTS_COMMIT_ERROR:
      return {
        ...state,
        runResultsCommit: createErrorState(action.payload.error),
      };
    case ESchedulingActions.GET_RUN_RESULTS_COMMIT_RESET:
      return {
        ...state,
        runResultsCommit: {
          data: null,
          requestStatus: {
            httpError: null,
            loading: false,
          },
        },
      };

    // --------------------------------------------------
    // Block Matching
    // --------------------------------------------------
    case ESchedulingActions.FETCH_BLOCK_MATCHING:
      return {
        ...state,
        blockMatching: createLoadingState(state.blockMatching),
      };
    case ESchedulingActions.FETCH_BLOCK_MATCHING_SUCCESS:
      return {
        ...state,
        blockMatching: createSuccessState(action.payload),
      };
    case ESchedulingActions.FETCH_BLOCK_MATCHING_FAILURE:
      return {
        ...state,
        blockMatching: createErrorState(action.payload.error),
      };

    case ESchedulingActions.EXPORT_BLOCK_MATCHING:
      return {
        ...state,
        exportBlockMatching: createLoadingState(state.blockMatching),
      };
    case ESchedulingActions.EXPORT_BLOCK_MATCHING_SUCCESS:
      return {
        ...state,
        exportBlockMatching: createSuccessState(null),
      };
    case ESchedulingActions.EXPORT_BLOCK_MATCHING_ERROR:
      return {
        ...state,
        exportBlockMatching: createErrorState(action.payload.error),
      };

    // --------------------------------------------------
    // SCHEDULING ORDER SETS
    // --------------------------------------------------
    case ESchedulingActions.GET_SCHEDULING_ORDER_SETS:
      return {
        ...state,
        schedulingOrderSets: createLoadingState(state.schedulingOrderSets),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SETS_SUCCESS:
      return {
        ...state,
        schedulingOrderSets: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SETS_ERROR:
      return {
        ...state,
        schedulingOrderSets: createErrorState(action.payload.error),
      };
    case ESchedulingActions.CLEAR_SCHEDULING_ORDER_SETS:
      return {
        ...state,
        schedulingOrderSets: clearState(state.schedulingOrderSets),
      };

    // --------------------------------------------------
    // SCHEDULING ORDER SET
    // --------------------------------------------------
    case ESchedulingActions.GET_SCHEDULING_ORDER_SET:
      return {
        ...state,
        schedulingOrderSet: createLoadingState(state.schedulingOrderSet),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SET_SUCCESS:
      return {
        ...state,
        schedulingOrderSet: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SET_ERROR:
      return {
        ...state,
        schedulingOrderSet: createErrorState(action.payload.error),
      };

    case ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE:
      return {
        ...state,
        schedulingOrderSet: createLoadingState(state.schedulingOrderSet),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE_SUCCESS:
      return {
        ...state,
        schedulingOrderSet: createSuccessState(action.payload),
      };
    case ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE_ERROR:
      return {
        ...state,
        schedulingOrderSet: createErrorState(action.payload.error),
      };

    case ESchedulingActions.CREATE_SCHEDULING_ORDER_SET:
      return {
        ...state,
        createSchedulingOrderSet: createLoadingState(state.createSchedulingOrderSet),
      };
    case ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_SUCCESS:
      return {
        ...state,
        createSchedulingOrderSet: createSuccessState(action.payload),
      };
    case ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_ERROR:
      return {
        ...state,
        createSchedulingOrderSet: createErrorState(action.payload.error),
      };
    case ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS:
      return {
        ...state,
        createSchedulingOrderSet: clearState(state.createSchedulingOrderSet),
      };

    case ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET:
      return {
        ...state,
        updateSchedulingOrderSet: createLoadingState(state.updateSchedulingOrderSet),
      };
    case ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_SUCCESS:
      return {
        ...state,
        updateSchedulingOrderSet: createSuccessState(action.payload),
      };
    case ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_ERROR:
      return {
        ...state,
        updateSchedulingOrderSet: createErrorState(action.payload.error),
      };
    case ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS:
      return {
        ...state,
        updateSchedulingOrderSet: clearState(state.updateSchedulingOrderSet),
      };

    case ESchedulingActions.DELETE_SCHEDULING_ORDER_SET:
      return {
        ...state,
        deleteSchedulingOrderSet: createLoadingState(state.deleteSchedulingOrderSet),
      };
    case ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_SUCCESS:
      return {
        ...state,
        deleteSchedulingOrderSet: createSuccessState(action.payload),
      };
    case ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_ERROR:
      return {
        ...state,
        deleteSchedulingOrderSet: createErrorState(action.payload.error),
      };
    case ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_CLEAR_ERRORS:
      return {
        ...state,
        deleteSchedulingOrderSet: clearState(state.deleteSchedulingOrderSet),
      };

    default:
      return state;
  }
}
