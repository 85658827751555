import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { IHeaderState } from './header.state';

const selectHeaderState = (state: IAppState) => state.headerState;

export const selectTsp = createSelector(
  selectHeaderState,
  (state: IHeaderState) => state.tsp
);

export const selectServiceRequester = createSelector(
  selectHeaderState,
  (state: IHeaderState) => state.serviceRequester
);

export const selectServiceRequesterOptions = createSelector(
  selectHeaderState,
  (state: IHeaderState) => state.serviceRequesters
);
