import { FormGroup } from '@angular/forms';
import {
  BlockMatchingResults,
  ChainMaxAvailableResultsCollection,
  ChainPathLimitsResultsCollection,
  KPathLimitsResultsCollection,
  NominationDetailResultsCollection,
  PLComponentResultsCollection,
  PriorityOfServiceLookup,
  ResultsOverview,
  RunResults,
  RunResultsCollection,
  SchedulingEngineOverrideCollection,
  SchedulingOrderSet,
  SchedulingOrderSetCollection,
  Template,
} from '@gms/scheduling-api';
import {
  createInitialDataState,
  createSuccessState,
  emptyRequestStatus,
  IDataState,
} from 'app/store/app/app.models';

export interface ISchedulingState {
  runs: Array<RunResults>;
  totalRunsCount: number;
  runsError: Error;
  runsLoading: boolean;

  userInitiated: boolean;

  runsForValidationLookup: IDataState<RunResultsCollection>;
  runsForNAESBCycle: IDataState<RunResultsCollection>;

  runSchedulingEngineError: Error;
  runSchedulingEngineLoading: boolean;
  runSchedulingEngineSuccess: boolean;

  Template: Template;
  TemplateError: Error;
  TemplateLoading: boolean;

  SchedulingPageInformation: { runResults: RunResults; actionBar: object };

  runResultsOverview: ResultsOverview;
  runResultsOverviewError: Error;
  runResultsOverviewLoading: boolean;

  createSeOverride: IDataState<SchedulingEngineOverrideCollection>;

  runResultsCommit: IDataState<boolean>;

  runResultsNomDetail: IDataState<NominationDetailResultsCollection>;

  runResultsPLComponent: IDataState<PLComponentResultsCollection>;

  exportPLComponent: IDataState<null>;

  runResultsKPathLimits: IDataState<KPathLimitsResultsCollection>;

  runResultsChainPathLimits: IDataState<ChainPathLimitsResultsCollection>;

  runResultsMaxAvailablePathLimits: IDataState<ChainMaxAvailableResultsCollection>;

  blockMatching: IDataState<BlockMatchingResults>;
  exportBlockMatching: IDataState<null>;

  priorityOfService: PriorityOfServiceLookup;
  priorityOfServiceCollection: IDataState<PriorityOfServiceLookup[]>;
  schedulingOrderSets: IDataState<SchedulingOrderSetCollection>;

  schedulingOrderSet: IDataState<SchedulingOrderSet>;

  createSchedulingOrderSet: IDataState<SchedulingOrderSet>;

  updateSchedulingOrderSet: IDataState<SchedulingOrderSet>;

  deleteSchedulingOrderSet: IDataState<SchedulingOrderSet>;
}

export const initialSchedulingState: ISchedulingState = {
  userInitiated: false,
  blockMatching: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  exportBlockMatching: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },

  createSeOverride: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },

  runResultsCommit: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runResultsNomDetail: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runResultsPLComponent: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  exportPLComponent: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runResultsKPathLimits: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runResultsChainPathLimits: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runResultsMaxAvailablePathLimits: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runsForValidationLookup: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  runsForNAESBCycle: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  schedulingOrderSets: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  schedulingOrderSet: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  createSchedulingOrderSet: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  updateSchedulingOrderSet: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  deleteSchedulingOrderSet: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },

  runs: null,
  totalRunsCount: null,
  runsError: null,
  runsLoading: false,

  runSchedulingEngineError: null,
  runSchedulingEngineLoading: false,
  runSchedulingEngineSuccess: false,

  Template: null,
  TemplateError: null,
  TemplateLoading: false,

  SchedulingPageInformation: { runResults: null, actionBar: null },

  runResultsOverview: null,
  runResultsOverviewError: null,
  runResultsOverviewLoading: false,
  priorityOfService: null,
  priorityOfServiceCollection: createInitialDataState(),
};
