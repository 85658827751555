import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { IAppState } from 'app/store/app/app.state';
import { NO_RETRY_ENDPOINTS } from 'app/store/auth/model/consts';
import { EEndpointFragment } from 'app/store/auth/model/enums';
import { appConfig } from 'config/app-config';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, filter, first, switchMap, take, zip } from 'rxjs/operators';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { HttpCodes } from 'shared/consts/http-codes.const';
import { LABELS } from 'shared/consts/labels.const';
import { ToastService } from 'shared/services/toast.service';
import { selectUserInitiated } from '../scheduling/scheduling.selectors';
import { Lock, LogOut, Refresh } from './auth.actions';
import { selectAccessToken, selectIsAuthenticated } from './auth.selectors';
import { ERumEvent, Rum } from 'app/rum.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store<IAppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method } = request;

    if (!url.includes(appConfig.cognito.uri)) {
      /**
       * @note If we are uploading a nomination, we need to
       * manually set the content-type header due to a bug/limitation in api-gateway,
       * so... contentType is a required property on the api and comes from the selected file
       *
       * @see 'src/app/modules/nominations/pages/nomination-upload/upload-nominations.component.ts' --> onUploadNominationsClick()
       */
      if (url.includes(EEndpointFragment.NomUpload)) {
        return this._store.pipe(
          select(selectAccessToken),
          take(1),
          switchMap(accessToken => {
            request = request.clone({
              setHeaders: {
                ...(accessToken ? { Authorization: accessToken } : {}),
              },
            });
            return next.handle(request);
          })
        );
      }
      // Set content type when Posting a new invoice attachment
      if (url.includes(EEndpointFragment.InvoiceAttachments) && method === 'POST') {
        return this._store.pipe(
          select(selectAccessToken),
          take(1),
          switchMap(accessToken => {
            request = request.clone({
              setHeaders: {
                ...(accessToken ? { Authorization: accessToken } : {}),
                'Content-Type': 'multipart/form-data',
              },
            });
            return next.handle(request);
          })
        );
      }

      // // Otherwise, we send 'application/json' headers for our standard data requests
      else {
        return this._store.pipe(
          select(selectAccessToken),
          take(1),
          switchMap(accessToken => {
            request = request.clone({
              setHeaders: {
                ...(accessToken ? { Authorization: accessToken } : {}),
                'Content-Type': 'application/json',
              },
            });
            return next.handle(request);
          })
        );
      }
    } else {
      return next.handle(request);
    }
  }
}

@Injectable()
export class ErrorInterceptor extends HasSubscriptions implements HttpInterceptor, OnInit {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly toastService: ToastService,
    private _rum: Rum,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this._store.pipe(select(selectIsAuthenticated)).subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          this.cancelAllAndLogout();
        }
      })
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: any) => {
        let nextAction: Observable<HttpEvent<any>>;

        const wasNoRetryRequest = NO_RETRY_ENDPOINTS.some(endpoint =>
          request.url.includes(endpoint)
        );

        if (wasNoRetryRequest) {
          if (
            !request.url.includes(EEndpointFragment.Login) &&
            !request.url.includes(EEndpointFragment.PasswordReset)
          ) {
            this._rum.recordEvent(ERumEvent.Update_Refresh_Token, {"Error": "retry auth request failed"});
            console.error('retry auth request failed');
            this._store.dispatch(new LogOut());
          } else if (
            response instanceof HttpErrorResponse &&
            response.status === HttpCodes.ACCOUNT_LOCKED
          ) {
            this._store.dispatch(new Lock());
          } else if (
            response instanceof HttpErrorResponse &&
            response.status === HttpCodes.CONFLICT
          ) {
            this.toastService.showError(LABELS.CONCURRENCY_ERROR);
          }
        } else if (
          response instanceof HttpErrorResponse &&
          response.status === HttpCodes.ACCOUNT_LOCKED
        ) {
          this._store.dispatch(new Lock());
        } else if (
          response instanceof HttpErrorResponse &&
          response.status === HttpCodes.UNAUTHORIZED
        ) {
          if (request.url.endsWith(EEndpointFragment.RunResults)) {
            nextAction = this._store.pipe(
              select(selectUserInitiated),
              take(1),
              switchMap(userInit => {
                if (userInit) {
                  return this.getNextRefreshTokenAction(nextAction, request, next, response);
                }
                return nextAction;
              })
            );
          }
          nextAction = this.getNextRefreshTokenAction(nextAction, request, next, response);
        } else if (
          response instanceof HttpErrorResponse &&
          response.status === HttpCodes.CONFLICT
        ) {
          this.toastService.showError(LABELS.CONCURRENCY_ERROR);
        }
        return nextAction || throwError(response);
      })
    );
  }

  getNextRefreshTokenAction(
    nextAction: Observable<HttpEvent<any>>,
    request: HttpRequest<any>,
    next: HttpHandler,
    response: HttpErrorResponse
  ) {
    if (this.refreshTokenInProgress) {
      nextAction = this.addRequestToRetryQueue(request, next);
    } else {
      nextAction = this.refreshTokenAndRetryRequest(request, next, response);
    }
    return nextAction;
  }

  addRequestToRetryQueue(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenSubject.pipe(
      filter(Boolean),
      take(1),
      switchMap((accessToken: string) => {
        return next.handle(this.addAuthenticationToken(request, accessToken));
      })
    );
  }

  addRetryObservable(
    request: HttpRequest<any>,
    next: HttpHandler,
    response: any
  ): Observable<HttpEvent<any>> {
    return this._store.pipe(
      select(selectAccessToken),
      filter(Boolean),
      zip(this._store.pipe(select(selectIsAuthenticated))),
      first(),
      switchMap(([accessToken, isAuthenticated]) => {
        let nextAction: Observable<HttpEvent<any>>;

        if (isAuthenticated) {
          this.refreshTokenSubject.next(accessToken);
          nextAction = next.handle(this.addAuthenticationToken(request, accessToken as string));
        } else {
          this.refreshTokenSubject.next(null);
          console.error('user Is not authenticated addRetryObservable');
          this._store.dispatch(new LogOut());
        }

        this.refreshTokenInProgress = false;

        return nextAction || throwError(response);
      }),
      catchError(error => {
        this.cancelAllAndLogout();
        return throwError(error);
      })
    );
  }

  refreshTokenAndRetryRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    response: any
  ): Observable<HttpEvent<any>> {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    this._store.dispatch(new Refresh());

    const retry = this.addRetryObservable(request, next, response);
    retry.subscribe();
    return retry;
  }

  private cancelAllAndLogout() {
    this.refreshTokenInProgress = false;
    this.refreshTokenSubject.next(null);
    console.error('cancelAllAndLogout');
    this._store.dispatch(new LogOut());
  }

  addAuthenticationToken(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        ...(accessToken ? { Authorization: accessToken } : {}),
        'Content-Type': 'application/json',
      },
    });
  }
}
