import { DetailRateSchedule, ReportDefinition } from '@gms/reporting-api';
import { Action } from '@ngrx/store';
import {
  EReportClientIdResponseModel,
  EReportParameterResponseModel,
} from 'app/store/reports/reports-response.model';

export enum EReportsActions {
  FETCH_REPORT_DEFINITION_BY_ID = '[REPORTS] Fetch Report Definition By Id',
  FETCH_REPORT_DEFINITION_BY_ID_SUCCESS = '[REPORTS] Fetch Report Definition By Id Success',
  FETCH_REPORT_DEFINITION_BY_ID_ERROR = '[REPORTS] Fetch Report Definition By Id Error',
  CLEAR_REPORT_DEFINITION = '[REPORTS] Clear Report Definition',
  FETCH_REPORT_CLIENT_ID = '[REPORTS] Fetch Report Client Id',
  FETCH_REPORT_CLIENT_ID_SUCCESS = '[REPORTS] Fetch Report Client Id Success',
  FETCH_REPORT_CLIENT_ID_ERROR = '[REPORTS] Fetch Report Client Id Error',
  CLEAR_REPORT_CLIENT_ID = '[REPORTS] Clear Report Client Id',
  FETCH_REPORT_PARAMETERS = '[REPORTS] Fetch Report Parameters',
  FETCH_REPORT_PARAMETERS_SUCCESS = '[REPORTS] Fetch Report Parameters Success',
  FETCH_REPORT_PARAMETERS_ERROR = '[REPORTS] Fetch Report Parameters Error',
  CLEAR_REPORT_PARAMETERS = '[REPORTS] Clear Report Parameters',
  SET_REPORT_PARAMETER_VALUES = '[REPORTS] Set Report Parameter Values',
  SET_REPORT_PARAMETER_VALUES_SUCCESS = '[REPORTS] Set Report Parameter Values Success',

  DOWNLOAD_PDF = '[REPORTS] Download PDF',
  DOWNLOAD_PDF_SUCCESS = '[REPORTS] Download PDF Success',
  DOWNLOAD_PDF_FAILURE = '[REPORTS] Download PDF Failure',
  CLIENT_DOWNLOAD_PDF_SUCCESS = '[REPORTS] Client Download PDF Success',
  CLIENT_DOWNLOAD_PDF_FAILURE = '[REPORTS] Client Download PDF Failure',

  FETCH_REPORT_DETAIL_RATE_SCHEDULE = '[RESPORTS] Fetch Report Detail Rate Schedule',
  FETCH_REPORT_DETAIL_RATE_SCHEDULE_SUCCESS = '[RESPORTS] Fetch Report Detail Rate Schedule Success',
  FETCH_REPORT_DETAIL_RATE_SCHEDULE_ERROR = '[RESPORTS] Fetch Report Detail Rate Schedule Failure',
}

export class FetchReportDefinitionById implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DEFINITION_BY_ID;

  constructor(public payload: { reportId: number }) {}
}

export class FetchReportDefinitionByIdSuccess implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DEFINITION_BY_ID_SUCCESS;

  constructor(public payload: { reportDefinition: ReportDefinition }) {}
}

export class FetchReportDefinitionByIdError implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DEFINITION_BY_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearReportDefinition implements Action {
  public readonly type = EReportsActions.CLEAR_REPORT_DEFINITION;

  constructor() {}
}

export class FetchReportClientId implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_CLIENT_ID;

  constructor() {}
}

export class FetchReportClientIdSuccess implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_CLIENT_ID_SUCCESS;

  constructor(public payload: EReportClientIdResponseModel) {}
}

export class FetchReportClientIdError implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_CLIENT_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearReportClientId implements Action {
  public readonly type = EReportsActions.CLEAR_REPORT_CLIENT_ID;

  constructor() {}
}

export class FetchReportParameters implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_PARAMETERS;

  constructor(public payload: { reportName: string; clientId: number }) {}
}

export class FetchReportParametersSuccess implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_PARAMETERS_SUCCESS;

  constructor(public payload: { parameterValues: Array<EReportParameterResponseModel> }) {}
}

export class FetchReportParametersError implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_PARAMETERS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearReportParameters implements Action {
  public readonly type = EReportsActions.CLEAR_REPORT_PARAMETERS;

  constructor() {}
}

export class SetReportParameterValues implements Action {
  public readonly type = EReportsActions.SET_REPORT_PARAMETER_VALUES;

  constructor(public payload: { parameters: any }) {}
}

export class SetReportParameterValuesSuccess implements Action {
  public readonly type = EReportsActions.SET_REPORT_PARAMETER_VALUES_SUCCESS;

  constructor(public payload: { parameters: any }) {}
}

export class DownloadPdf implements Action {
  public readonly type = EReportsActions.DOWNLOAD_PDF;

  constructor(public payload: { url: string; pdfName: string; fileExtension: string }) {}
}

export class DownloadPdfSuccess implements Action {
  public readonly type = EReportsActions.DOWNLOAD_PDF_SUCCESS;

  constructor(public payload: { pdfBlob: Blob; pdfName: string; fileExtension: string }) {}
}

export class DownloadPdfFailure implements Action {
  public readonly type = EReportsActions.DOWNLOAD_PDF_FAILURE;

  constructor(public payload: { error: Error }) {}
}
export class ClientDownloadPdfSuccess implements Action {
  public readonly type = EReportsActions.CLIENT_DOWNLOAD_PDF_SUCCESS;

  constructor() {}
}

export class ClientDownloadPdfFailure implements Action {
  public readonly type = EReportsActions.CLIENT_DOWNLOAD_PDF_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchReportDetailRateSchedule implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE;

  constructor(public payload: { tspId: number }) {}
}

export class FetchReportDetailRateScheduleSuccess implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE_SUCCESS;

  constructor(public payload: DetailRateSchedule) {}
}

export class FetchReportDetailRateScheduleError implements Action {
  public readonly type = EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export type ReportsActions =
  | FetchReportDefinitionById
  | FetchReportDefinitionByIdSuccess
  | FetchReportDefinitionByIdError
  | ClearReportDefinition
  | FetchReportClientId
  | FetchReportClientIdSuccess
  | FetchReportClientIdError
  | ClearReportClientId
  | FetchReportParameters
  | FetchReportParametersSuccess
  | FetchReportParametersError
  | ClearReportParameters
  | SetReportParameterValues
  | SetReportParameterValuesSuccess
  | DownloadPdf
  | DownloadPdfSuccess
  | DownloadPdfFailure
  | ClientDownloadPdfSuccess
  | ClientDownloadPdfFailure
  | FetchReportDetailRateSchedule
  | FetchReportDetailRateScheduleSuccess
  | FetchReportDetailRateScheduleError;
