import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { pairwise } from 'rxjs/operators';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { dateUtils } from 'shared/utils/date.utils';

@Component({
  selector: 'app-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
})
export class FormDatepickerComponent extends HasSubscriptions implements OnInit {
  /**
   * forces the dates to be set to All of the World dates on init.
   */
  @Input()
  forceAOE = true;

  /**
   * forces the dates to be set to All of the World dates on any programmatic change to the date. *unless it is already in AOE format.
   */
  @Input()
  forceAOEAggressively = true;

  @Input()
  disabled = false;
  @Input()
  id: string;
  @Input()
  value: any;
  @Input()
  bottomView = 'month';
  @Input()
  control: UntypedFormControl;
  @Input()
  label: string;
  @Input()
  placeholder = 'MM/DD/YYYY';
  @Input()
  focusedDate: Date = new Date();
  @Input()
  format = 'MM/dd/yyyy';
  @Input()
  errorMessage: string;
  @Input()
  customClass = '';

  @Input()
  isInATable = false;
  @Input()
  isInActionBar = false;

  @Input()
  maxErrorMessage: string;
  @Input()
  minErrorMessage: string;
  @Input()
  min: Date;
  @Input()
  max: Date;
  @Input()
  formatPlaceholder: any = "{ year: 'YYYY', month: 'MM', day: 'DD' }";
  @Input()
  displayType: 'Secondary' | 'Primary' = 'Primary';

  // There are only two sizes for form input components.
  // 48px, which occurs outside of tables
  // 40px, which occurs inside of tables
  @Input()
  size: 'Small' | 'Large' = 'Large';

  /**
   * Toggles the built-in rangeValidation when using min and max values
   * Useful when using CustomValidators and getting inconsistent valid/invalid styles
   * @default true
   */
  @Input()
  rangeValidation: true;

  isFocused;

  @Output()
  readonly datepickerFocused: EventEmitter<Event> = new EventEmitter();

  @Output()
  readonly valueChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly datepickerBlurred: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.forceAOE) {
      this.forceAOEChange();

      if (this.forceAOEAggressively) {
        this.addSubscription(
          this.control.valueChanges.pipe(pairwise()).subscribe(([previousValue, currentValue]) => {
            if (previousValue && currentValue) {
              const date = dateUtils.getAOEDateFor(this.control.value);
              if (!!dateUtils.compare(date, previousValue)) {
                this.control.setValue(date);
                this._valueChanged(date);
              }
            }
          })
        );
      }
    }
  }

  forceAOEChange() {
    if (this.control.value) {
      const date = dateUtils.getAOEDateFor(this.control.value);
      if (!!dateUtils.compare(date, this.control.value)) {
        this.control.setValue(date);
        this._valueChanged(date);
      }
    }
  }

  _valueChanged(v) {
    this.valueChanged.emit(v);
  }

  onFocus(e) {
    this.isFocused = true;
    this.datepickerFocused.emit();
  }

  blur(e) {
    this.isFocused = false;
    this.datepickerBlurred.emit();
  }

  today() {
    // set today in CT time in AOE time
    this.control.setValue(dateUtils.getAOEDateFor(dateUtils.convertToCT(new Date())));
    this._valueChanged(this.control.value);
  }

  close(e) {
    $('button.k-calendar-nav-today')[0].removeEventListener('click', () => {});
  }

  open(e) {
    setTimeout(() => {
      $('button.k-calendar-nav-today')[0].addEventListener('click', () => {
        this.today();
      });
    }, 60);
  }
}
