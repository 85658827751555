import { DetailRateSchedule, ReportDefinition } from '@gms/reporting-api';
import {
  EReportClientIdResponseModel,
  EReportParameter,
  EReportParameterResponseModel,
} from 'app/store/reports/reports-response.model';
import { emptyRequestStatus, IDataState } from '../app/app.models';

export interface IReportsState {
  reportDefinition: IDataState<ReportDefinition>;
  reportClientId: IDataState<EReportClientIdResponseModel>;
  reportParameters: IDataState<Array<EReportParameterResponseModel>>;
  selectedReportParameters: IDataState<Array<EReportParameter>>;
  reportRateSchedule: IDataState<DetailRateSchedule>;
}

export const initialReportsState: IReportsState = {
  reportDefinition: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  reportClientId: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  reportParameters: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  selectedReportParameters: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  reportRateSchedule: {
    data: {},
    requestStatus: { ...emptyRequestStatus },
  },
};
