/* The name of each property doesn't follow our camel casing convention, however the property names match the properties in the DB.
Writing the property names as the DB format > writing glue code to convert from camel case to DB readable properties */
import { AccountingPeriod } from '@gms/billing-api';
import { ServiceProvider } from '@gms/tsp-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { IPagination } from 'shared/models/pagination.model';
import { isNullOrUndefined } from 'shared/utils/type.utils';

export const masterAttributes = {
  chargeBasis: { id: 80002, label: 'Charge Basis' },
  chargePeriod: { id: 80003, label: 'Charge Period' },
  rateComponent: { id: 80001, label: 'Rate Component' },
  rateZoneDependency: { id: 80005, label: 'Rate Zone Dependency' },
  seasonalRate: { id: 80004, label: 'Seasonal Rate' },
};

export const orderOfMasterAttributes = [
  'rateComponent',
  'chargeBasis',
  'chargePeriod',
  'seasonalRate',
  'rateZoneDependency',
];

export const effectiveRateDictionary = {
  percentUnnominated: '% Unnominated',
  withdrawalDays: 'Withdrawal Days',
  receiptLocationName: 'REC LOC/NAME',
  receiptGroupName: 'REC LOC GRP/NAME',
  deliveryLocationName: 'DEL LOC/NAME',
  deliveryGroupName: 'DEL LOC GRP/NAME',
  receiptZone: 'Receipt Zone',
  deliveryZone: 'Delivery Zone',
  receiptZoneCode: 'Receipt Zone',
  deliveryZoneCode: 'Delivery Zone',
  maxRate: 'Maximum Rate',
  minRate: 'Minimum Rate',
  peakWinterMaxRate: 'Peak(Winter) Maximum&nbsp;Rate',
  peakWinterMinRate: 'Peak(Winter) Minimum&nbsp;Rate',
  offPeakSummerMaxRate: 'Off-Peak(Summer) Maximum&nbsp;Rate',
  offPeakSummerMinRate: 'Off-Peak(Summer) Minimum&nbsp;Rate',
  recLocName: 'REC LOC/NAME',
  recLocGrpName: 'REC LOC GRP/NAME',
  delLocName: 'DEL LOC/NAME',
  delLocGrpName: 'DEL LOC GRP/NAME',
  deliveryFuelZone: 'Delivery Fuel Zone',
  rate: 'Rate',
  hybridRate: 'Hybrid Rate',
};

export const rateZoneCodeToNameMap = {
  '5': '%Unnominated / Withdrawal Days',
  '6': 'Delivery Zone',
  '7': 'Delivery Fuel Zone',
};

export enum RateZoneDependencyNames {
  DELIVERY_ZONE = 'Delivery Zone',
  DELIVERY_FUEL_ZONE = 'Delivery Fuel Zone',
  FUEL_ZONE_TO_FUEL_ZONE = 'Fuel Zone to Fuel Zone',
  LOC_LOC_GROUP = 'Loc / Loc Grp',
  PERCENT_UNNOMINATED_WITHDRAWAL_DAYS = '% Unnominated/Withdrawal Days',
  ZONE_TO_ZONE = 'Zone to Zone',
}

export interface IViewBy {
  code: string;
  name: string;
}

export interface ChargeTypesListNavigationState {
  pagination: IPagination;
  sortDescriptors: Array<SortDescriptor>;
  tsp: ServiceProvider;
  headerFilters: any;
  quickSearchFilters: any;
  hiddenColumns: [];
  listView: string;
}

type TransactionStatusEnum = 'PRELIMINARY' | 'FINAL';

const TransactionStatusEnum = {
  PRELIMINARY: 'Preliminary' as TransactionStatusEnum,
  FINAL: 'Final' as TransactionStatusEnum,
};

export const transactionsColorMap = {
  [TransactionStatusEnum.PRELIMINARY]: TagColor.YELLOW,
  [TransactionStatusEnum.FINAL]: TagColor.PRIMARY_BLUE,
};

type IncludeExcludeEnum = 'I' | 'E';

const IncludeExcludeEnum = {
  I: 'I' as IncludeExcludeEnum,
  E: 'E' as IncludeExcludeEnum,
};

export const includeExcludeMap = {
  [IncludeExcludeEnum.I]: 'Include',
  [IncludeExcludeEnum.E]: 'Exclude',
};

export enum AttributeEnum {
  TransactionType = 85001,
  PipelineSystem = 85004,
  Zone,
  Region,
  LocationNumber,
  LocationGroup,
  Classification,
  ChargeType,
  LocationAttribute,
  Other,
  LocationEndPath,
  Accounts,
  ContractAttribute,
  AccountTypes,
  QtySign,
  ImbalanceType,
  CapacityReleaseType,
  RateSchedule,
  GroupedAttributes = 85999,
  PipelineSystemMeasurement = 86000,
  ImbalanceStorageTradeDirection = 86001,
  ContractOrigin = 86002,
}

export const otherTspCriteria = [
  { name: 'Zero Fuel Point Pairs', code: 0 },
  { name: 'Same Gathering ID', code: 1 },
  { name: 'Zero Fuel Point Pairs for Fuel', code: 2 },
];

export const journalEntryTypeDictionary = [
  { name: 'All', value: null },
  { name: 'Both', value: 3 },
  { name: 'Inventory', value: 2 },
  { name: 'Revenue', value: 1 },
];

export const imbalanceStorageTradeDirectionDictionary = [
  { name: 'Injection', code: 1},
  { name: 'Withdrawal', code: 2}
]

export enum JournalEntryTypeEnum {
  Revenue = 1,
  Inventory = 2,
  Both = 3,
}

export enum FormulaOperatorEnum {
  '+' = 1,
  '-' = 2,
}

export enum TariffRateWorkflowUserRoles {
  Billing = 37,
  Regulatory = 24,
}

export enum TariffRateStatusEnum {
  Approved = 1,
  Pending,
  'Review-Billing',
  Rejected,
}

export const OperatorCollection = [
  { code: FormulaOperatorEnum['+'], name: '+' },
  { code: FormulaOperatorEnum['-'], name: '-' },
];

export const tariffRateColorMap = {
  [TariffRateStatusEnum['Review-Billing']]: TagColor.YELLOW,
  [TariffRateStatusEnum.Rejected]: TagColor.RED,
  [TariffRateStatusEnum.Approved]: TagColor.GREEN,
  [TariffRateStatusEnum.Pending]: TagColor.YELLOW,
};

export enum AccountTypeEnum {
  ImbalancePetal = 1,
  ImbalanceStorage = 2,
  ImbalanceStoragePetal = 3,
  ImbalanceStorageSCO = 4,
  ImbalanceTransportation = 5,
  Operational = 6,
  SusPenseGas = 7,
  UnauthorizedGas = 8,
  ImbalanceContractStorage = 9,
}

export enum AccountTypeAbbreviationEnum {
  ImbalancePetal = 'IMB-P',
  ImbalanceStorage = 'IMB-NNS',
  ImbalanceStoragePetal = 'IMB-NNSA',
  ImbalanceStorageSCO = 'IMB-SCO',
  ImbalanceTransportation = 'IMB',
  ImbalanceContractStorage = 'IMB-STO',
}

export enum ContractualRateLevel {
  Contract = 'Contract',
  Blanket = 'Blanket',
  AccountId = 'Account ID',
  AccountType = 'Account Type',
  ServiceRequester = 'Service Requester',
}

export enum ContractualRateLevelEnum {
  Contract = 'C',
  Blanket = 'B',
  AccountId = 'I',
  AccountType = 'A',
  ServiceRequester = 'S',
}

export enum ActivityTypeShortCodeEnum {
  Trades = 'TD',
  Transfers = 'TR',
  CashOuts = 'CO',
  NNSImbalance = 'NI',
  ImbalanceStorageTrade = 'IT',
  NNSContractImbalance = 'NK',
}

export enum ActivityTypeNameEnum {
  Trades = 'Trades',
  Transfers = 'Transfers',
  CashOuts = 'Cash Outs',
  NNSImbalance = 'NNS Imbalances',
  ImbalanceStorageTrade = 'Imbalance Storage Trade',
  NNSContractImbalance = 'NNS Contract Imbalances',
}

export const typesOfDisqualifications = {
  flowLocations: 'flowRules',
  segmentationTotals: 'segmentationTotals',
};

export const RateToApply = ['Max Tariff', 'Other'];

export const QuantityDisqualificationsFlow = [
  { displayName: 'Entire MDQ' },
  { displayName: 'Equivalent Qty' },
];

export const QuantityDisqualificationsSegmentation = {
  EntireMDQ: 'Entire MDQ',
  AllocatedQty: 'Allocated Qty',
};

export const AccountTypeCollection = [
  { id: AccountTypeEnum.ImbalancePetal, shortName: AccountTypeAbbreviationEnum.ImbalancePetal },
  { id: AccountTypeEnum.ImbalanceStorage, shortName: AccountTypeAbbreviationEnum.ImbalanceStorage },
  {
    id: AccountTypeEnum.ImbalanceStoragePetal,
    shortName: AccountTypeAbbreviationEnum.ImbalanceStoragePetal,
  },
  {
    id: AccountTypeEnum.ImbalanceStorageSCO,
    shortName: AccountTypeAbbreviationEnum.ImbalanceStorageSCO,
  },
  {
    id: AccountTypeEnum.ImbalanceTransportation,
    shortName: AccountTypeAbbreviationEnum.ImbalanceTransportation,
  },
  {
    id: AccountTypeEnum.ImbalanceContractStorage,
    shortName: AccountTypeAbbreviationEnum.ImbalanceContractStorage,
  },
];

export const ActivityTypeCollection = [
  { code: null, name: 'Select', shortCode: null },
  { code: 1, name: ActivityTypeNameEnum.Trades, shortCode: ActivityTypeShortCodeEnum.Trades },
  {
    code: 2,
    name: ActivityTypeNameEnum.Transfers,
    shortCode: ActivityTypeShortCodeEnum.Transfers,
  },
  { code: 3, name: ActivityTypeNameEnum.CashOuts, shortCode: ActivityTypeShortCodeEnum.CashOuts },
  {
    code: 4,
    name: ActivityTypeNameEnum.NNSImbalance,
    shortCode: ActivityTypeShortCodeEnum.NNSImbalance,
  },
  {
    code: 5,
    name: ActivityTypeNameEnum.ImbalanceStorageTrade,
    shortCode: ActivityTypeShortCodeEnum.ImbalanceStorageTrade,
  },
  {
    code: 6,
    name: ActivityTypeNameEnum.NNSContractImbalance,
    shortCode: ActivityTypeShortCodeEnum.NNSContractImbalance,
  },
];

export const AccountType_ActivityTypeCollection = [
  { code: null, name: 'Select', shortCode: null },
  { code: 1, name: ActivityTypeNameEnum.Trades, shortCode: ActivityTypeShortCodeEnum.Trades },
  { code: 3, name: ActivityTypeNameEnum.CashOuts, shortCode: ActivityTypeShortCodeEnum.CashOuts },
  {
    code: 4,
    name: ActivityTypeNameEnum.NNSImbalance,
    shortCode: ActivityTypeShortCodeEnum.NNSImbalance,
  },
  {
    code: 6,
    name: ActivityTypeNameEnum.NNSContractImbalance,
    shortCode: ActivityTypeShortCodeEnum.NNSContractImbalance,
  },
];

export const RateSchedule_ActivityTypeCollection = [
  { code: null, name: 'Select', shortCode: null },
  { code: 3, name: ActivityTypeNameEnum.CashOuts, shortCode: ActivityTypeShortCodeEnum.CashOuts },
  {
    code: 2,
    name: ActivityTypeNameEnum.Transfers,
    shortCode: ActivityTypeShortCodeEnum.Transfers,
  },
  {
    code: 5,
    name: ActivityTypeNameEnum.ImbalanceStorageTrade,
    shortCode: ActivityTypeShortCodeEnum.ImbalanceStorageTrade,
  },
];

export enum ContractualRatesPriceTypeEnum {
  Dollar = 'D',
  Percentage = 'P',
  IndexOrMisc = 'I',
}

export enum IndexRateTypeEnum {
  Fixed = 1,
  Calculated = 2,
  FixedPercent = 3,
}

export const ActivityLabel = {
  ActivityType: 'Activity Type',
  ReceiptDelivery: 'Receipt/Delivery',
};

export const CalculatedPriceTypesConstants = {
  HighPrice: 'High Price',
  LowPrice: 'Low Price',
  MidpointPrice: 'Midpoint Price',
  MonthlyPrice: 'Monthly Price',
};

export type InvoiceExceptionType = 'Error' | 'Info' | 'TechnicalError';

export class InvoiceExceptionTypes {
  public static values: { id: number; name: string; value: InvoiceExceptionType }[] = [
    { id: -1, name: 'All', value: null },
    { id: 0, name: 'Error', value: 'Error' },
    { id: 1, name: 'Info', value: 'Info' },
    { id: 2, name: 'Technical Error', value: 'TechnicalError' },
  ];

  static get All(): { id: number; name: string; value: InvoiceExceptionType } {
    return this.values[0];
  }
}

export function formatPrice_$_CCCC(value: number, notDefined: any = '') {
  return isNullOrUndefined(value) ? notDefined : '$' + value.toFixed(4);
}

export function formatPricePercent(value: number, notDefined: any = '') {
  return isNullOrUndefined(value) ? notDefined : (100 * value).toFixed(2) + ' %';
}

export enum TariffRatePriceTypeCode {
  FIXED = 'F',
  PERCENT = '%',
  INDEX_OR_MISC = 'I',
}

export enum TariffRatePriceTypeName {
  FIXED = 'Fixed',
  PERCENT = 'Percent',
  INDEX_OR_MISC = 'Index or Misc',
}

export const DisplayedTariffRatePriceTypeMap = {
  [TariffRatePriceTypeCode.FIXED]: 'Fixed ($)',
  [TariffRatePriceTypeCode.PERCENT]: 'Percent (%)',
  [TariffRatePriceTypeCode.INDEX_OR_MISC]: 'Index or Misc',

  [TariffRatePriceTypeName.FIXED]: 'Fixed ($)',
  [TariffRatePriceTypeName.PERCENT]: 'Percent (%)',
  [TariffRatePriceTypeName.INDEX_OR_MISC]: 'Index or Misc',
};

enum AccountingPeriodStatus {
  CLOSED = 'Closed',
  OPEN = 'Open',
}

export function generateDefaultAccountingPeriod(
  accountingPeriods: AccountingPeriod[]
): IAccountPeriod {
  if (!accountingPeriods || !accountingPeriods.length) return null;

  let earliestOpenPeriod = accountingPeriods.find(
    ({ status }) => status === AccountingPeriodStatus.OPEN
  );

  if (!earliestOpenPeriod) earliestOpenPeriod = accountingPeriods[accountingPeriods.length - 1];

  const defaultPeriod = earliestOpenPeriod.accountingPeriodDate;
  const [year, mon] = defaultPeriod.split('-');

  return {
    date: defaultPeriod,
    id: `${parseInt(mon, 10)}${year}`,
    mon,
    year,
  };
}

export function formatAccountingPeriods(
  accountingPeriods: AccountingPeriod[] = []
): IAccountPeriod[] {
  return [...accountingPeriods].reverse().map(({ accountingPeriodDate, status }) => {
    const [year, mon] = accountingPeriodDate.split('-');
    return {
      date: accountingPeriodDate,
      id: `${parseInt(mon, 10)}${year}`,
      mon,
      year,
      status,
    };
  });
}
