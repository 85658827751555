import { Injectable } from '@angular/core';
import {
  BlockMatchingResults,
  ChainMaxAvailableResultsCollection,
  ChainPathLimitsResultsCollection,
  KPathLimitsResultsCollection,
  NominationDetailResultsCollection,
  PLComponentResultsCollection,
  PriorityOfServiceLookup,
  PriorityOfServiceService,
  ResultsOverview,
  RunRequest,
  RunResultsCollection,
  RunResultsService,
  RunsService,
  SchedulingEngineOverrideCollection,
  SchedulingOrderSetsService,
  SchedulingOverridesService,
  SchedulingService,
  Template,
  TemplateService,
} from '@gms/scheduling-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';
import {
  CreateSchedulingOrderSet,
  CreateSchedulingOrderSetError,
  CreateSchedulingOrderSetSuccess,
  CreateSEOverride,
  CreateSEOverrideError,
  CreateSEOverrideSuccess,
  DeleteSchedulingOrderSet,
  DeleteSchedulingOrderSetError,
  DeleteSchedulingOrderSetSuccess,
  ESchedulingActions,
  ExportBlockMatching,
  ExportBlockMatchingError,
  ExportBlockMatchingSuccess,
  ExportPLComponent,
  ExportPLComponentError,
  ExportPLComponentSuccess,
  FetchBlockMatching,
  FetchBlockMatchingFailure,
  FetchBlockMatchingSuccess,
  FetchPriorityOfServiceResults,
  FetchPriorityOfServiceResultsFailure,
  FetchPriorityOfServiceResultsSuccess,
  FetchRunResults,
  FetchRunResultsError,
  FetchRunResultsSuccess,
  FetchRunResultsValidationLookup,
  FetchRunResultsValidationLookupError,
  FetchRunResultsValidationLookupSuccess,
  FetchRunResultsNAESBCycle,
  FetchRunResultsNAESBCycleError,
  FetchRunResultsNAESBCycleSuccess,
  GetRunResultsCommit,
  GetRunResultsCommitError,
  GetRunResultsCommitSuccess,
  GetRunResultsNomDetail,
  GetRunResultsNomDetailError,
  GetRunResultsNomDetailSuccess,
  GetSchedulingEngineRunResultsChainPathLimits,
  GetSchedulingEngineRunResultsChainPathLimitsError,
  GetSchedulingEngineRunResultsChainPathLimitsSuccess,
  GetSchedulingEngineRunResultsKPathLimits,
  GetSchedulingEngineRunResultsKPathLimitsError,
  GetSchedulingEngineRunResultsKPathLimitsSuccess,
  GetSchedulingEngineRunResultsMaxPathLimits,
  GetSchedulingEngineRunResultsMaxPathLimitsError,
  GetSchedulingEngineRunResultsMaxPathLimitsSuccess,
  GetSchedulingEngineRunResultsOverview,
  GetSchedulingEngineRunResultsOverviewError,
  GetSchedulingEngineRunResultsOverviewSuccess,
  GetSchedulingEngineRunResultsPLComponent,
  GetSchedulingEngineRunResultsPLComponentError,
  GetSchedulingEngineRunResultsPLComponentSuccess,
  GetSchedulingEngineTemplate,
  GetSchedulingEngineTemplateError,
  GetSchedulingEngineTemplateSuccess,
  GetSchedulingOrderSet,
  GetSchedulingOrderSetError,
  GetSchedulingOrderSetLive,
  GetSchedulingOrderSetLiveError,
  GetSchedulingOrderSetLiveSuccess,
  GetSchedulingOrderSets,
  GetSchedulingOrderSetsError,
  GetSchedulingOrderSetsSuccess,
  GetSchedulingOrderSetSuccess,
  IChainPathLimitsResultsPayload,
  IKPathLimitsResultsPayload,
  IMaxLimitsResultsPayload,
  IPriorityOfServiceResultsPayload,
  IRunResultsNomDetailPayload,
  IRunResultsPayload,
  IRunResultsPLComponentPayload,
  RunSchedulingEngine,
  RunSchedulingEngineError,
  RunSchedulingEngineSuccess,
  SchedulingTemplate,
  UpdateSchedulingOrderSet,
  UpdateSchedulingOrderSetError,
  UpdateSchedulingOrderSetSuccess,
} from 'app/store/scheduling/scheduling.actions';
import get from 'lodash/get';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  cleanDateWithMM_DD_YYYY,
  cleanDateWithYYYY_MM_DD,
} from 'shared/services/data-cleaner.service';

@Injectable()
export class SchedulingEffects {
  constructor(
    private _actions$: Actions,
    private _runResultsService: RunResultsService,
    private _schedulingService: SchedulingService,
    private _priorityOfServiceService: PriorityOfServiceService,
    private _schedulingOrderedSetsService: SchedulingOrderSetsService,
    private _schedulingOverridesService: SchedulingOverridesService,
    private _runsService: RunsService,
    private _templateService: TemplateService
  ) {}

  FetchRunResults: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchRunResults>(ESchedulingActions.FETCH_RUN_RESULTS),
    map((action: FetchRunResults) => action.payload),
    switchMap((payload: IRunResultsPayload) => {
      return this._runResultsService
        .getResults(
          payload.cycleCode,
          payload.runType,
          payload.date,
          payload.dateType,
          payload.tspId
        )
        .pipe(
          map((runResults: RunResultsCollection) => {
            return new FetchRunResultsSuccess({
              runResults: runResults.runResults,
              total: runResults.total,
            });
          }),
          catchError(error => of(new FetchRunResultsError({ error: error })))
        );
    })
  ));

  FetchPriorityOfServiceResults: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchPriorityOfServiceResults>(ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS),
    map((action: FetchPriorityOfServiceResults) => action.payload),
    switchMap((payload: IPriorityOfServiceResultsPayload) => {
      return this._priorityOfServiceService
        .getPriorityOfservice(payload.tspId, payload.smpFlag)
        .pipe(
          map((response: PriorityOfServiceLookup[]) => {
            return new FetchPriorityOfServiceResultsSuccess(response);
          }),
          catchError((error: Error) => of(new FetchPriorityOfServiceResultsFailure({ error })))
        );
    })
  ));
  
  FetchRunResultsValidationLookup: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchRunResultsValidationLookup>(ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP),
    map((action: FetchRunResultsValidationLookup) => action.payload),
    switchMap((payload: IRunResultsPayload) => {
      return this._runResultsService
        .getResults(
          payload.cycleCode,
          payload.runType,
          payload.date,
          payload.dateType,
          payload.tspId
        )
        .pipe(
          map((runResults: RunResultsCollection) => {
            return new FetchRunResultsValidationLookupSuccess({
              runResults: runResults.runResults,
              total: runResults.total,
            });
          }),
          catchError(error => of(new FetchRunResultsValidationLookupError({ error: error })))
        );
    })
  ));

  FetchRunResultsNAESBCycle: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchRunResultsNAESBCycle>(ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE),
    map((action: FetchRunResultsNAESBCycle) => action.payload),
    switchMap((payload: IRunResultsPayload) => {
      return this._runResultsService
        .getResults(
          payload.cycleCode,
          payload.runType, 
          payload.date,
          payload.dateType,
          payload.tspId
        )
        .pipe(
          map((runResults: RunResultsCollection) => {
            return new FetchRunResultsNAESBCycleSuccess({
              runResults: runResults.runResults,
              total: runResults.total,
            });
          }),
          catchError(error => of(new FetchRunResultsNAESBCycleError({ error: error })))
        );
    })
  ));

  RunSchedulingEngine: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<RunSchedulingEngine>(ESchedulingActions.RUN_SCHEDULING_ENGINE),
    map((action: RunSchedulingEngine) => action.payload),
    switchMap((user: RunRequest) => {
      return this._runsService.runSchedulingEngine(user).pipe(
        map(() => {
          return new RunSchedulingEngineSuccess();
        }),
        catchError(error => of(new RunSchedulingEngineError(error)))
      );
    })
  ));

  GetRunResultsNomDetail: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetRunResultsNomDetail>(ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL),
    map((action: GetRunResultsNomDetail) => action.payload),
    switchMap((payload: IRunResultsNomDetailPayload) => {
      return this._schedulingService
        .getResultsNomDetail(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds,
          payload.onlyShowReductions,
          payload.transactionTypeCode,
          payload.tspStepDisplayOrderNumbers,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((runResults: NominationDetailResultsCollection) => {
            return new GetRunResultsNomDetailSuccess({
              nominationDetailResults: get(runResults, 'nominationDetailResults', []),
              total: get(runResults, 'total', 0),
            });
          }),
          catchError(error => {
            return of(new GetRunResultsNomDetailError({ error: error }));
          })
        );
    })
  ));

  GetSchedulingEngineTemplate: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineTemplate>(ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE),
    map((action: GetSchedulingEngineTemplate) => action.payload),
    switchMap((template: SchedulingTemplate) => {
      return this._templateService.getTemplate(template.cycleType, template.runTypeId).pipe(
        map((schedulingEngineTemplate: Template) => {
          return new GetSchedulingEngineTemplateSuccess(schedulingEngineTemplate);
        }),
        catchError(error => of(new GetSchedulingEngineTemplateError(error)))
      );
    })
  ));

  GetSchedulingEngineRunResultsOverview: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineRunResultsOverview>(
      ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW
    ),
    map((action: GetSchedulingEngineRunResultsOverview) => action.payload),
    switchMap((seRunId: number) => {
      return this._schedulingService.getResultsOverview(seRunId).pipe(
        map((schedulingEngineRunResultsOverview: ResultsOverview) => {
          return new GetSchedulingEngineRunResultsOverviewSuccess(
            schedulingEngineRunResultsOverview
          );
        }),
        catchError(error => of(new GetSchedulingEngineRunResultsOverviewError(error)))
      );
    })
  ));

  GetSchedulingEngineRunResultsKPathLimits: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineRunResultsKPathLimits>(
      ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS
    ),
    map((action: GetSchedulingEngineRunResultsKPathLimits) => action.payload),
    switchMap((payload: IKPathLimitsResultsPayload) => {
      return this._schedulingService
        .getResultsKPathLimits(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds,
          payload.onlyShowReductions,
          payload.rateSchedule,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((kPathLimitsResultsCollection: KPathLimitsResultsCollection) => {
            return new GetSchedulingEngineRunResultsKPathLimitsSuccess({
              nominationDetailResults: get(
                kPathLimitsResultsCollection,
                'nominationDetailResults',
                []
              ),
              total: get(kPathLimitsResultsCollection, 'total', 0),
            });
          }),
          catchError(error => of(new GetSchedulingEngineRunResultsKPathLimitsError(error)))
        );
    })
  ));

  GetSchedulingEngineRunResultsPLComponent: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineRunResultsPLComponent>(
      ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT
    ),
    map((action: GetSchedulingEngineRunResultsPLComponent) => action.payload),
    switchMap((payload: IRunResultsPLComponentPayload) => {
      return this._schedulingService
        .getResultsplComponent(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds,
          payload.onlyShowReductions,
          payload.componentType,
          payload.viewBy,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((plComponentResultsCollection: PLComponentResultsCollection) => {
            return new GetSchedulingEngineRunResultsPLComponentSuccess({
              plComponentResults: get(plComponentResultsCollection, 'plComponentResults', []),
              total: get(plComponentResultsCollection, 'total', 0),
            });
          }),
          catchError(error =>
            of(new GetSchedulingEngineRunResultsPLComponentError({ error: error }))
          )
        );
    })
  ));

  ExportPLComponent$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<ExportPLComponent>(ESchedulingActions.EXPORT_PL_COMPONENT),
    map((action: ExportPLComponent) => action.payload),
    switchMap(payload => {
      const saveData = (data: string) => {
        const blob = new Blob([atob(data)], { type: 'text/csv' });
        const fileName =
          'Capacity-Scheduling-' +
          `${cleanDateWithMM_DD_YYYY(payload.GasDayOnDatSet, '-')}` +
          '-' +
          `${payload.seRunId}` +
          '-' +
          `${payload.cycle}`;
        saveAs(blob, `${fileName}.csv`);
      };

      return this._schedulingService
        .exportPLComponent(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds,
          payload.onlyShowReductions,
          payload.componentType,
          payload.viewBy,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map(response => {
            saveData(response);
            return new ExportPLComponentSuccess();
          }),
          catchError(error => {
            return of(new ExportPLComponentError(error));
          })
        );
    })
  ));

  GetSchedulingEngineRunResultsChainPathLimits: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineRunResultsChainPathLimits>(
      ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS
    ),
    map((action: GetSchedulingEngineRunResultsChainPathLimits) => action.payload),
    switchMap((payload: IChainPathLimitsResultsPayload) => {
      return this._schedulingService
        .getResultsChainPathLimits(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds,
          payload.onlyShowReductions,
          payload.rateSchedule,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((chainPathLimitsResultsCollection: ChainPathLimitsResultsCollection) => {
            return new GetSchedulingEngineRunResultsChainPathLimitsSuccess({
              chainPathLimitsResults: get(
                chainPathLimitsResultsCollection,
                'chainPathLimitsResults',
                []
              ),
              total: get(chainPathLimitsResultsCollection, 'total', 0),
            });
          }),
          catchError(error => of(new GetSchedulingEngineRunResultsChainPathLimitsError(error)))
        );
    })
  ));

  GetSchedulingEngineRunResultsMaxPathLimits: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingEngineRunResultsMaxPathLimits>(
      ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS
    ),
    map((action: GetSchedulingEngineRunResultsMaxPathLimits) => action.payload),
    switchMap((payload: IMaxLimitsResultsPayload) => {
      return this._schedulingService
        .getResultsChainMaxAvailable(
          payload.seRunId,
          payload.locationNumbers,
          payload.schedulingGroups,
          payload.flowDirection,
          payload.locationClassification,
          payload.entityId,
          payload.serviceRequestContractIds && payload.serviceRequestContractIds.length > 0
            ? payload.serviceRequestContractIds.map(n => n.toString())
            : null,
          payload.onlyShowReductions,
          payload.rateSchedule,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((chainMaxAvailableResultsCollection: ChainMaxAvailableResultsCollection) => {
            return new GetSchedulingEngineRunResultsMaxPathLimitsSuccess({
              nominationDetailResults: get(
                chainMaxAvailableResultsCollection,
                'nominationDetailResults',
                []
              ),
              total: get(chainMaxAvailableResultsCollection, 'total', 0),
            });
          }),
          catchError(error => of(new GetSchedulingEngineRunResultsMaxPathLimitsError(error)))
        );
    })
  ));

  CreateSEOverride: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateSEOverride>(ESchedulingActions.CREATE_SE_OVERRIDE),
    map((action: CreateSEOverride) => action.payload),
    switchMap((payload: SchedulingEngineOverrideCollection) => {
      return this._schedulingOverridesService.createSEOverride(payload).pipe(
        map((collection: SchedulingEngineOverrideCollection) => {
          return new CreateSEOverrideSuccess(collection);
        }),
        catchError(error => {
          return of(new CreateSEOverrideError(error));
        })
      );
    })
  ));

  GetRunResultsCommit: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetRunResultsCommit>(ESchedulingActions.GET_RUN_RESULTS_COMMIT),
    map((action: GetRunResultsCommit) => action.payload),
    switchMap(payload => {
      return this._schedulingService.commitRunResults(payload.seRunId, payload.validationOnly).pipe(
        map(() => {
          return new GetRunResultsCommitSuccess(true);
        }),
        catchError(error => of(new GetRunResultsCommitError({ error: error })))
      );
    })
  ));

  FetchBlockMatching: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBlockMatching>(ESchedulingActions.FETCH_BLOCK_MATCHING),
    map((action: FetchBlockMatching) => action.payload),
    switchMap(payload => {
      return this._schedulingService
        .getBlockMatchingResults(
          payload.seRunId,
          payload.serviceRequesterId,
          payload.pageSize,
          payload.pageNumber
        )
        .pipe(
          map((item: BlockMatchingResults) => new FetchBlockMatchingSuccess(item)),
          catchError(error => of(new FetchBlockMatchingFailure({ error: error })))
        );
    })
  ));

  ExportBlockMatching$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<ExportBlockMatching>(ESchedulingActions.EXPORT_BLOCK_MATCHING),
    map((action: ExportBlockMatching) => action.payload),
    switchMap(payload => {
      const saveData = (data: string) => {
        const blob = new Blob([atob(data)], { type: 'text/csv' });
        const fileName = 'Block Matching Results-' + payload.gasDay + '-' + `${payload.seRunId}`;
        saveAs(blob, fileName);
      };
      return this._schedulingService
        .exportBlockMatching(payload.seRunId, payload.serviceRequesterId)
        .pipe(
          map(response => {
            saveData(response);
            return new ExportBlockMatchingSuccess();
          }),
          catchError(error => {
            return of(new ExportBlockMatchingError(error));
          })
        );
    })
  ));

  GetSchedulingOrderSets: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingOrderSets>(ESchedulingActions.GET_SCHEDULING_ORDER_SETS),
    map((action: GetSchedulingOrderSets) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService.getSchedulingOrderSets(payload.tspId).pipe(
        map(response => {
          return new GetSchedulingOrderSetsSuccess(response);
        }),
        catchError(error => of(new GetSchedulingOrderSetsError({ error: error })))
      );
    })
  ));

  GetSchedulingOrderSet: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingOrderSet>(ESchedulingActions.GET_SCHEDULING_ORDER_SET),
    map((action: GetSchedulingOrderSet) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService
        .getSchedulingOrderSet(payload.schedulingOrderSetId)
        .pipe(
          map(response => {
            return new GetSchedulingOrderSetSuccess(response);
          }),
          catchError(error => of(new GetSchedulingOrderSetError({ error: error })))
        );
    })
  ));

  GetSchedulingOrderSetLive: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetSchedulingOrderSetLive>(ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE),
    map((action: GetSchedulingOrderSetLive) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService.getSchedulingOrderSetLive(payload.tspId).pipe(
        map(response => {
          return new GetSchedulingOrderSetLiveSuccess(response);
        }),
        catchError(error => of(new GetSchedulingOrderSetLiveError({ error: error })))
      );
    })
  ));

  CreateSchedulingOrderSet: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateSchedulingOrderSet>(ESchedulingActions.CREATE_SCHEDULING_ORDER_SET),
    map((action: CreateSchedulingOrderSet) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService
        .postSchedulingOrderSet(payload.schedulingOrderSet)
        .pipe(
          map(response => {
            return new CreateSchedulingOrderSetSuccess(response);
          }),
          catchError(error => of(new CreateSchedulingOrderSetError({ error: error })))
        );
    })
  ));

  UpdateSchedulingOrderSet: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateSchedulingOrderSet>(ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET),
    map((action: UpdateSchedulingOrderSet) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService
        .postSchedulingOrderSet(payload.schedulingOrderSet)
        .pipe(
          map(response => {
            return new UpdateSchedulingOrderSetSuccess(response);
          }),
          catchError(error => of(new UpdateSchedulingOrderSetError({ error: error })))
        );
    })
  ));

  DeleteSchedulingOrderSet: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteSchedulingOrderSet>(ESchedulingActions.DELETE_SCHEDULING_ORDER_SET),
    map((action: DeleteSchedulingOrderSet) => action.payload),
    switchMap(payload => {
      return this._schedulingOrderedSetsService
        .deleteSchedulingOrderSet(payload.schedulingOrderSetId)
        .pipe(
          map(response => {
            return new DeleteSchedulingOrderSetSuccess(response);
          }),
          catchError(error => of(new DeleteSchedulingOrderSetError({ error: error })))
        );
    })
  ));
}
