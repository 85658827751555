import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { dateUtils } from 'shared/utils/date.utils';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  BalancingImbalance,
  BalancingImbalanceCollection,
  BalancingImbalanceDetails,
  ContractImbalanceCollection,
  ImbalanceCollection,
  ImbalanceService,
  ImbalanceSummary,
} from '@gms/allocation-api';
import {
  AuthorizedImbalanceAccountDetailCollection,
  ImbalanceAuthorization,
  ImbalanceAuthorizationCollection,
  ImbalanceAuthorizationToPostService,
  ImbalanceAuthorizedTradeAccountsService,
  ImbalanceImbalancePipelinePositionService,
  ImbalanceImbalanceTradesService,
  ImbalanceImbalanceTradesTradeWindowStatusService,
  ImbalanceImbalanceTradeSummaryService,
  ImbalanceInventorySearch,
  ImbalanceInventoryService,
  ImbalanceObaExportService,
  ImbalanceObaPostService,
  ImbalanceOperationalBalancingAgreementService,
  ImbalancePipelinePosition,
  ImbalanceShipperActivityExportService,
  ImbalanceTrade,
  ImbalanceTspImbalanceConfigService,
  InventoryCollection,
  MasterRateCICOScheduleCollection,
  MasterRateCicoSchedulesService,
  OperationalBalancingAgmt,
  OperationalBalancingAgmtCollection,
  ShipperActivity,
  ShipperActivityContractsService,
  ShipperActivityPostService,
  ShipperActivityService,
  StorageBalanceDetailService,
  StorageStorageBalanceExportService,
  StorageStorageBalanceMonthService,
  TradeWindow,
} from '@gms/imbalance-api';
import {
  convertAccountPeriodToDate,
  generateAccountingPeriods,
} from 'app/modules/accounting/utils/journal-entries-utils';
import { IMBALANCE_LABELS } from 'app/modules/flowing-gas/imbalance.labels';
import { EImbalanceTspAttribute } from 'app/store/imbalances/imbalance.utils';
import {
  CreateImbalanceTrade,
  CreateImbalanceTradeFailure,
  CreateImbalanceTradeSuccess,
  EditObaSummaryFailure,
  EditObaSummaryRequest,
  EditObaSummarySuccess,
  EditShipperActivityFailure,
  EditShipperActivityRequest,
  EditShipperActivitySuccess,
  EImbalancesActions,
  ExportImbalanceDetails,
  ExportImbalanceDetailsFailure,
  ExportImbalanceDetailsSuccess,
  ExportObaSummaryFailure,
  ExportObaSummarySuccess,
  ExportShipperActivity,
  ExportShipperActivityFailure,
  ExportShipperActivitySuccess,
  ExportStorageBalance,
  ExportStorageBalanceFailure,
  ExportStorageBalanceSuccess,
  FetchAcctImbalance,
  FetchAcctImbalanceError,
  FetchAcctImbalanceSuccess,
  FetchAuthToPostImbalancesCollection,
  FetchAuthToPostImbalancesCollectionFailure,
  FetchAuthToPostImbalancesCollectionSuccess,
  FetchBalancingAgreementImbalance,
  FetchBalancingAgreementImbalanceDetails,
  FetchBalancingAgreementImbalanceDetailsFailure,
  FetchBalancingAgreementImbalanceDetailsSuccess,
  FetchBalancingAgreementImbalanceFailure,
  FetchBalancingAgreementImbalanceSuccess,
  FetchImbalanceAccountingPeriods,
  FetchImbalanceAccountingPeriodsSuccess,
  FetchImbalanceDetails,
  FetchImbalanceDetailsFailure,
  FetchImbalanceDetailsSuccess,
  FetchImbalanceInventories,
  FetchImbalanceInventoriesError,
  FetchImbalanceInventoriesSuccess,
  FetchImbalances,
  FetchImbalancesFailure,
  FetchImbalancesSuccess,
  FetchImbalanceSummary,
  FetchImbalanceSummaryFailure,
  FetchImbalanceSummarySuccess,
  FetchImbalanceTradesCollection,
  FetchImbalanceTradesCollectionFailure,
  FetchImbalanceTradesCollectionSuccess,
  FetchImbalanceTradesSummaryCollection,
  FetchImbalanceTradesSummaryCollectionFailure,
  FetchImbalanceTradesSummaryCollectionSuccess,
  FetchMasterRateCICOScheduleCollection,
  FetchMasterRateCICOScheduleCollectionFailure,
  FetchMasterRateCICOScheduleCollectionSuccess,
  FetchObaSummaryFailure,
  FetchObaSummaryRequest,
  FetchObaSummarySuccess,
  FetchPipelinePosition,
  FetchPipelinePositionFailure,
  FetchPipelinePositionSuccess,
  FetchShipperActivityCollection,
  FetchShipperActivityCollectionFailure,
  FetchShipperActivityCollectionSuccess,
  FetchShipperActivityContractCollection,
  FetchShipperActivityContractCollectionFailure,
  FetchShipperActivityContractCollectionSuccess,
  FetchStorageBalanceCollection,
  FetchStorageBalanceCollectionFailure,
  FetchStorageBalanceCollectionSuccess,
  FetchTradeWindow,
  FetchTradeWindowFailure,
  FetchTradeWindowSuccess,
  GetAuthorizedImbalanceAccounts,
  GetAuthorizedImbalanceAccountsFailure,
  GetAuthorizedImbalanceAccountsSuccess,
  PutAuthToPostImbalances,
  PutAuthToPostImbalancesFailure,
  PutAuthToPostImbalancesSuccess,
  PutBalancingAgreementImbalanceSignoff,
  PutBalancingAgreementImbalanceSignoffFailure,
  PutBalancingAgreementImbalanceSignoffSuccess,
  UpdateImbalanceTrade,
  UpdateImbalanceTradeFailure,
  UpdateImbalanceTradeSuccess,
  ViewBy,
  WithdrawImbalanceTrade,
  WithdrawImbalanceTradeFailure,
  WithdrawImbalanceTradeSuccess,
} from 'app/store/imbalances/imbalances.actions';
import { HttpCodes } from 'shared/consts/http-codes.const';
import { cleanDateWithMM_DD_YYYY_HH_MM_AM_PM_TZ } from 'shared/services/data-cleaner.service';
import { ToastService } from 'shared/services/toast.service';

@Injectable()
export class ImbalancesEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _toastService: ToastService,
    private _imbalanceService: ImbalanceService,
    private _imbalanceInventoryService: ImbalanceInventoryService,
    private _imbalanceTradesService: ImbalanceImbalanceTradesService,
    private _imbalanceTradesSummaryService: ImbalanceImbalanceTradeSummaryService,
    private _shipperActivityService: ShipperActivityService,
    private _shipperActivityContractService: ShipperActivityContractsService,
    private _shipperActivityExportService: ImbalanceShipperActivityExportService,
    private _storageBalanceService: StorageStorageBalanceMonthService,
    private _storageBalanceDetailService: StorageBalanceDetailService,
    private _contractsBalanceExportService: StorageStorageBalanceExportService,
    private _authToPostService: ImbalanceAuthorizationToPostService,
    private _imbalanceTspConfigService: ImbalanceTspImbalanceConfigService,
    private _imbalanceTradeAccountsService: ImbalanceAuthorizedTradeAccountsService,
    private _imbalanceObaPostService: ImbalanceObaPostService,
    private _shipperAcivityPostService: ShipperActivityPostService,
    private _imbalanceObaExportService: ImbalanceObaExportService,
    private _imbalanceOperationalBalancingAgreementService: ImbalanceOperationalBalancingAgreementService,
    private _imbalanceTradeWindow: ImbalanceImbalanceTradesTradeWindowStatusService,
    private _imbalancePipelinePosition: ImbalanceImbalancePipelinePositionService,
    private _masterRateCicoSchedulesService: MasterRateCicoSchedulesService
  ) {}

  FetchImbalances: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalances>(EImbalancesActions.FETCH_IMBALANCES),
      map((action: FetchImbalances) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }

        return this._imbalanceService
          .getImbalance(
            payload.tspId,
            payload.serviceRequestor,
            payload.accountingPeriod,
            payload.contractId,
            payload.pageSize,
            payload.pageNumber
          )
          .pipe(
            map(
              (imbalanceCollection: ImbalanceCollection) =>
                new FetchImbalancesSuccess({
                  imbalances: imbalanceCollection.imbalances,
                  totalImbalancesCount: imbalanceCollection.total,
                })
            ),
            catchError(error => of(new FetchImbalancesFailure(error)))
          );
      })
    )
  );

  FetchImbalanceDetails: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceDetails>(EImbalancesActions.FETCH_IMBALANCE_DETAILS),
      map((action: FetchImbalanceDetails) => action.payload),
      switchMap(payload => {
        const viewBy = payload.viewBy === ViewBy.Summary ? 'Summary' : 'Detail';

        return this._imbalanceService
          .getContractImbalance(
            payload.contractId,
            payload.accountingPeriod,
            payload.viewBy,
            payload.pageSize,
            payload.pageNumber
          )
          .pipe(
            map(
              (contractImbalanceCollection: ContractImbalanceCollection) =>
                new FetchImbalanceDetailsSuccess({
                  contractImbalances: contractImbalanceCollection.contractImbalances,
                  totalImbalancesCount: contractImbalanceCollection.total,
                })
            ),
            catchError(error => {
              return of(new FetchImbalanceDetailsFailure(error));
            })
          );
      })
    )
  );

  ExportImbalanceDetais$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportImbalanceDetails>(EImbalancesActions.EXPORT_IMBALANCE_DETAILS),
      map((action: ExportImbalanceDetails) => action.payload),
      switchMap(payload => {
        const { contractId, accountingPeriod } = payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(
            blob,
            `Imbalance Details - ${dateUtils.getDateStringAsMM_YYYY(
              accountingPeriod
            )} ${contractId}.csv`
          );
        };

        return this._imbalanceService
          .getContractImbalanceExport(payload.contractId, payload.accountingPeriod)
          .pipe(
            map(response => {
              saveData(response);
              return new ExportImbalanceDetailsSuccess();
            }),
            catchError(error => of(new ExportImbalanceDetailsFailure(error)))
          );
      })
    )
  );

  FetchImbalanceAccountingPeriods: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceAccountingPeriods>(
        EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS
      ),
      map((action: FetchImbalanceAccountingPeriods) => action.payload),
      switchMap(payload =>
        of(
          new FetchImbalanceAccountingPeriodsSuccess({
            accountPeriods: generateAccountingPeriods(payload.monthsBack, payload.showAllOption),
          })
        )
      )
    )
  );

  FetchImbalanceSummary: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceSummary>(EImbalancesActions.FETCH_IMBALANCE_SUMMARY),
      map((action: FetchImbalanceSummary) => action.payload),
      switchMap(payload => {
        return this._imbalanceService
          .getImbalanceSummary(payload.tspId, payload.serviceRequstorId, payload.accountingPeriod)
          .pipe(
            map(
              (imbalanceSummary: ImbalanceSummary) =>
                new FetchImbalanceSummarySuccess(imbalanceSummary)
            ),
            catchError(error => {
              return of(new FetchImbalanceSummaryFailure(error));
            })
          );
      })
    )
  );

  FetchBalancingAgreementImbalance: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchBalancingAgreementImbalance>(
        EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE
      ),
      map((action: FetchBalancingAgreementImbalance) => action.payload),
      switchMap(payload => {
        const { tspId, serviceRequstorId, accountingPeriod } = payload;
        return this._imbalanceService
          .getBalancingAgreementImbalance(tspId, accountingPeriod, serviceRequstorId)
          .pipe(
            map((balancingImbalanceCollection: BalancingImbalanceCollection) => {
              balancingImbalanceCollection.imbalances.map((imbalance: BalancingImbalance) => {
                if (imbalance.signoffStatus && imbalance.signoffStatus.lastUpdate) {
                  imbalance.signoffStatus.lastUpdate = cleanDateWithMM_DD_YYYY_HH_MM_AM_PM_TZ(
                    new Date(imbalance.signoffStatus.lastUpdate)
                  );
                }
              });

              return new FetchBalancingAgreementImbalanceSuccess({
                obaImbalances: balancingImbalanceCollection.imbalances,
                total: balancingImbalanceCollection.total,
              });
            }),
            catchError(error => {
              return of(new FetchBalancingAgreementImbalanceFailure({ error }));
            })
          );
      })
    )
  );

  FetchBalancingAgreementImbalanceDetails: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchBalancingAgreementImbalanceDetails>(
        EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS
      ),
      map((action: FetchBalancingAgreementImbalanceDetails) => action.payload),
      switchMap(payload => {
        const { contractId, accountingPeriod } = payload;
        return this._imbalanceService.getObaImbalanceDetails(contractId, accountingPeriod).pipe(
          map(
            (balancingAgreemenetImbalanceDetails: BalancingImbalanceDetails) =>
              new FetchBalancingAgreementImbalanceDetailsSuccess({
                contractId: payload.contractId,
                balancingAgreementImbalanceDetails: balancingAgreemenetImbalanceDetails,
              })
          ),
          catchError(error => {
            return of(
              new FetchBalancingAgreementImbalanceDetailsFailure({
                error: error,
                contractId: payload.contractId,
              })
            );
          })
        );
      })
    )
  );

  fetchImbalanceTradesCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceTradesCollection>(EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION),
      map((action: FetchImbalanceTradesCollection) => action),
      switchMap(action => {
        const {
          tspId,
          accountPeriod,
          svcReqNameId,
          statusId,
          pageSize,
          pageNumber,
          sortDescriptors,
        } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = sortDescriptors
          ? `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`
          : null;
        return this._imbalanceTradesService
          .getImbalanceTrades(
            tspId,
            accountPeriodInDateType,
            svcReqNameId,
            statusId,
            pageSize,
            pageNumber,
            sortBy
          )
          .pipe(
            map(response => new FetchImbalanceTradesCollectionSuccess(response)),
            catchError(error => of(new FetchImbalanceTradesCollectionFailure(error)))
          );
      })
    )
  );

  fetchImbalanceTradesSummaryCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceTradesSummaryCollection>(
        EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION
      ),
      map((action: FetchImbalanceTradesSummaryCollection) => action),
      switchMap(action => {
        const {
          tspId,
          accountPeriod,
          svcReqNameId,
          statusId,
          pageSize,
          pageNumber,
          sortDescriptors,
        } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = sortDescriptors
          ? `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`
          : null;
        return this._imbalanceTradesSummaryService
          .getImbalanceTradeSummary(
            tspId,
            accountPeriodInDateType,
            svcReqNameId,
            statusId,
            pageSize,
            pageNumber,
            sortBy
          )
          .pipe(
            map(response => new FetchImbalanceTradesSummaryCollectionSuccess(response)),
            catchError(error => of(new FetchImbalanceTradesSummaryCollectionFailure(error)))
          );
      })
    )
  );

  withdrawImbalanceTrade$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<WithdrawImbalanceTrade>(EImbalancesActions.WITHDRAW_IMBALANCE_TRADE),
      map((action: WithdrawImbalanceTrade) => action),
      switchMap(action => {
        return this._imbalanceTradesService
          .withdrawImbalanceTrade(action.payload, action.payload.id)
          .pipe(
            map((updatedTrade: ImbalanceTrade) => {
              const acctPerSplit = updatedTrade.accountingPeriod.toString().split('-');
              this._toastService.success('The trade has been successfully withdrawn');
              this._router.navigate(['/imbalance/imbalance-trade'], {
                state: {
                  tradeAccountPeriodId: parseInt(acctPerSplit[1], 10).toString() + acctPerSplit[0],
                },
              });
              return new WithdrawImbalanceTradeSuccess(updatedTrade);
            }),
            catchError(error => of(new WithdrawImbalanceTradeFailure(error)))
          );
      })
    )
  );

  fetchStorageBalanceCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchStorageBalanceCollection>(EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION),
      map((action: FetchStorageBalanceCollection) => action),
      switchMap(action => {
        const {
          tspId,
          viewBy,
          svcReqNameId,
          acctPeriod,
          pageSize,
          pageNumber,
          svcReqK,
          beginDate,
          endDate,
        } = action.payload;
        const accountPeriodInDateType = !!acctPeriod.id
          ? convertAccountPeriodToDate(acctPeriod)
          : null;
        let httpCallResponse: Observable<any>;
        if (viewBy === IMBALANCE_LABELS.SUMMARY) {
          httpCallResponse = this._storageBalanceService.getStorageBalanceByMonth(
            accountPeriodInDateType,
            pageNumber,
            pageSize,
            tspId,
            svcReqNameId
          );
        } else {
          httpCallResponse = this._storageBalanceDetailService.getStorageBalanceDetail(
            pageSize,
            pageNumber,
            tspId,
            accountPeriodInDateType,
            svcReqNameId ? svcReqNameId.toString().replace(/[,]/g, '+') : null,
            svcReqK,
            beginDate,
            endDate
          );
        }
        return httpCallResponse.pipe(
          map(response => new FetchStorageBalanceCollectionSuccess(response)),
          catchError(error => of(new FetchStorageBalanceCollectionFailure(error)))
        );
      })
    )
  );

  exportStorageBalance$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportStorageBalance>(EImbalancesActions.EXPORT_STORAGE_BALANCE),
      map((action: ExportStorageBalance) => action),
      switchMap(action => {
        const {
          tspId,
          tspName,
          viewBy,
          svcReqNameId,
          acctPeriod,
          endDate,
          beginDate,
          svcReqK,
          format
        } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(acctPeriod);
        if(format === ".csv"){
          const saveData = (data: string) => {
            const blob = new Blob([data], { type: 'text/csv' });
            saveAs(
              blob,
              `${viewBy} - ${acctPeriod.mon}_${acctPeriod.year} - ${svcReqNameId ||
              'All'} - ${tspName}.csv`
            );
          };
          return this._contractsBalanceExportService
            .exportStorageBalances(
              accountPeriodInDateType,
              tspId,
              viewBy,
              svcReqNameId,
              svcReqK,
              beginDate,
              endDate,
              format
            )
            .pipe(
              map((response: string) => {
                saveData(atob(response));
                return new ExportStorageBalanceSuccess();
              }),
              catchError(error => of(new ExportStorageBalanceFailure(error)))
            );
        }
        else{
          return this._contractsBalanceExportService
            .exportStorageBalances(
              accountPeriodInDateType,
              tspId,
              viewBy,
              svcReqNameId,
              svcReqK,
              beginDate,
              endDate,
              format
            )
            .pipe(
              map((response: string) => {
                window.open(response.replaceAll('"', ''), "_blank");
                return new ExportStorageBalanceSuccess();
              }),
              catchError(error => of(new ExportStorageBalanceFailure(error)))
            );
        }

      })
    )
  );

  createImbalanceTrade$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateImbalanceTrade>(EImbalancesActions.CREATE_IMBALANCE_TRADE),
      map((action: CreateImbalanceTrade) => action),
      switchMap(action => {
        return this._imbalanceTradesService.addImbalanceTrade(action.payload).pipe(
          map((newCreatedTrade: ImbalanceTrade) => {
            const acctPerSplit = newCreatedTrade.accountingPeriod.toString().split('-');
            this._toastService.success('Your trade has been successfully submitted.');
            this._router.navigate(['/imbalance/imbalance-trade'], {
              state: {
                tradeAccountPeriodId: parseInt(acctPerSplit[1], 10).toString() + acctPerSplit[0],
              },
            });
            return new CreateImbalanceTradeSuccess(newCreatedTrade);
          }),
          catchError(error => of(new CreateImbalanceTradeFailure(error)))
        );
      })
    )
  );

  fetchShipperActivityContractCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchShipperActivityContractCollection>(
        EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION
      ),
      map((action: FetchShipperActivityContractCollection) => action.payload),
      switchMap(payload => {
        return this._shipperActivityContractService
          .getShipperActivityContracts(
            payload.entityIds,
            payload.tspId,
            null,
            payload.accountPeriodStart,
            payload.accountPeriodEnd,
            payload.pageSize,
            payload.pageNumber
          )
          .pipe(
            map(
              contractCollection =>
                new FetchShipperActivityContractCollectionSuccess(contractCollection)
            ),
            catchError(error => of(new FetchShipperActivityContractCollectionFailure(error)))
          );
      })
    )
  );

  fetchShipperActivityCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchShipperActivityCollection>(EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION),
      map((action: FetchShipperActivityCollection) => action),
      switchMap(action => {
        const {
          tspId,
          svcReqNameId,
          svcReqKId,
          viewBy,
          beginningAccountingPeriod,
          endingAccountingPeriod,
          pageSize,
          pageNumber,
          sortDescriptors,
        } = action.payload;
        const fetchShipperActivityCollection = this._shipperActivityService.getShipperActivity(
          !!svcReqNameId ? svcReqNameId.join('+') : null,
          tspId,
          svcReqKId,
          viewBy,
          convertAccountPeriodToDate(beginningAccountingPeriod),
          !!endingAccountingPeriod
            ? new Date(+endingAccountingPeriod.year, +endingAccountingPeriod.mon, 0)
            : null,
          pageSize,
          pageNumber
          // sortDescriptors - TO DO: Implement Sorting
        );
        const fetchImbalanceTspConfig = this._imbalanceTspConfigService
          .getTspImbalConfigByTspAndAttr(tspId || 0, EImbalanceTspAttribute.IN_KIND_PERCENT)
          .pipe(
            catchError(error => {
              if (error.status !== HttpCodes.NOT_FOUND && !!tspId) {
                this._toastService.showError('Failed to get Imbalance TSP configuration');
              }
              return of(error);
            })
          );
        return forkJoin([fetchImbalanceTspConfig, fetchShipperActivityCollection]).pipe(
          map(
            ([tspConfig, shipperActivityCollection]) =>
              new FetchShipperActivityCollectionSuccess({
                shipperActivityCollection: shipperActivityCollection,
                tspConfig,
              })
          ),
          catchError(error => of(new FetchShipperActivityCollectionFailure(error)))
        );
      })
    )
  );

  updateImbalanceTrade$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateImbalanceTrade>(EImbalancesActions.UPDATE_IMBALANCE_TRADE),
      map((action: UpdateImbalanceTrade) => action),
      switchMap(action => {
        return this._imbalanceTradesService
          .confirmOrRejectImbalanceTrades(
            action.payload.trade as ImbalanceTrade,
            action.payload.trade.id
          )
          .pipe(
            map(
              (newUpdatedTrade: ImbalanceTrade) => new UpdateImbalanceTradeSuccess(newUpdatedTrade)
            ),
            catchError(error => of(new UpdateImbalanceTradeFailure(error)))
          );
      })
    )
  );

  exportShipperActivity$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportShipperActivity>(EImbalancesActions.EXPORT_SHIPPER_ACTIVITY),
      map((action: ExportShipperActivity) => action),
      switchMap(action => {
        const {
          tspId,
          tspName,
          svcReqNameId,
          svcReqKId,
          viewBy,
          beginningAccountingPeriod,
          endingAccountingPeriod,
        } = action.payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(
            blob,
            `ShipperActivity_${viewBy}_${tspName ||
              'All TSPs'}_Begin_${beginningAccountingPeriod.mon +
              beginningAccountingPeriod.year}_End_${endingAccountingPeriod.mon +
              endingAccountingPeriod.year}.csv`
          );
        };
        return this._shipperActivityExportService
          .exportShipperActivity(
            !!svcReqNameId ? svcReqNameId.join('+') : null,
            tspId,
            svcReqKId,
            viewBy,
            convertAccountPeriodToDate(beginningAccountingPeriod),
            !!endingAccountingPeriod
              ? new Date(+endingAccountingPeriod.year, +endingAccountingPeriod.mon, 0)
              : null
          )
          .pipe(
            map((response: string) => {
              saveData(response);
              return new ExportShipperActivitySuccess();
            }),
            catchError(error => of(new ExportShipperActivityFailure(error)))
          );
      })
    )
  );

  fetchAuthToPostImbalances$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAuthToPostImbalancesCollection>(
        EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION
      ),
      map((action: FetchAuthToPostImbalancesCollection) => action),
      switchMap(action => {
        return this._authToPostService
          .getAuthorizationPostings(action.payload.tspId, action.payload.svcReqNameId)
          .pipe(
            map(
              (authToPostCollection: ImbalanceAuthorizationCollection) =>
                new FetchAuthToPostImbalancesCollectionSuccess(authToPostCollection)
            ),
            catchError(error => of(new FetchAuthToPostImbalancesCollectionFailure(error)))
          );
      })
    )
  );

  putAuthToPostImbalances$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<PutAuthToPostImbalances>(EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES),
      map((action: PutAuthToPostImbalances) => action),
      switchMap(action => {
        return this._authToPostService
          .updateAuthorizationPosting(
            action.payload.imbalanceAuth,
            action.payload.imbalanceAuth.accountId
          )
          .pipe(
            map(
              (imbalanceAuth: ImbalanceAuthorization) =>
                new PutAuthToPostImbalancesSuccess(imbalanceAuth)
            ),
            catchError(error => of(new PutAuthToPostImbalancesFailure(error)))
          );
      })
    )
  );

  putBalancingAgreementImbalanceSignoff$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<PutBalancingAgreementImbalanceSignoff>(
        EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF
      ),
      map((action: PutBalancingAgreementImbalanceSignoff) => action.payload),
      switchMap(payload => {
        return this._imbalanceService
          .putObaImbalanceSignoff(payload.contractId, payload.balancingImbalanceSignoff, 'response')
          .pipe(
            map(
              (
                response: HttpResponse<any> //TODO: get record type for returned value
              ) =>
                new PutBalancingAgreementImbalanceSignoffSuccess({
                  signoffResponse: response,
                })
            ),
            catchError(error => of(new PutBalancingAgreementImbalanceSignoffFailure(error)))
          );
      })
    )
  );

  fetchAuthorizedImbalanceAccounts$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAuthorizedImbalanceAccounts>(
        EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS
      ),
      map((action: GetAuthorizedImbalanceAccounts) => action),
      switchMap(() => {
        return this._imbalanceTradeAccountsService.getAuthImbalanceTradeAccounts().pipe(
          map(
            (accounts: AuthorizedImbalanceAccountDetailCollection) =>
              new GetAuthorizedImbalanceAccountsSuccess(accounts)
          ),
          catchError(error => of(new GetAuthorizedImbalanceAccountsFailure(error)))
        );
      })
    )
  );

  editObaSummary$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<EditObaSummaryRequest>(EImbalancesActions.EDIT_OBA_SUMMARY_REQUEST),
      switchMap(({ payload }) =>
        this._imbalanceObaPostService.postObaSummary(payload).pipe(
          map((oba: OperationalBalancingAgmt) => new EditObaSummarySuccess(oba)),
          catchError(error => of(new EditObaSummaryFailure(error)))
        )
      )
    )
  );

  editShipperActivity$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<EditShipperActivityRequest>(EImbalancesActions.EDIT_SHIPPER_ACTIVITY_REQUEST),
      switchMap(({ payload }) =>
        this._shipperAcivityPostService.postShipperActivity(payload).pipe(
          map(
            (shipperActivity: ShipperActivity) => new EditShipperActivitySuccess(shipperActivity)
          ),
          catchError(error => of(new EditShipperActivityFailure(error)))
        )
      )
    )
  );

  exportObaSummary$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchObaSummaryRequest>(EImbalancesActions.EXPORT_OBA_SUMMARY_REQUEST),
      switchMap(
        ({
          payload: {
            accountPeriodEnd,
            accountPeriodStart,
            contractIds,
            dateBegin,
            dateEnd,
            entityIds,
            tspId,
          },
        }) => {
          const saveData = (data: string) => {
            const blob = new Blob([atob(data)], { type: 'text/csv' });
            saveAs(blob, 'Operational_Balancing_Summary.csv');
          };

          return this._imbalanceObaExportService
            .exportOBASummary(
              accountPeriodStart,
              accountPeriodEnd,
              entityIds,
              contractIds,
              tspId,
              dateBegin,
              dateEnd
            )
            .pipe(
              map((response: string) => {
                saveData(response);
                return new ExportObaSummarySuccess();
              }),
              catchError(error => of(new ExportObaSummaryFailure(error)))
            );
        }
      )
    )
  );

  fetchObaSummary$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchObaSummaryRequest>(EImbalancesActions.FETCH_OBA_SUMMARY_REQUEST),
      switchMap(
        ({
          payload: {
            accountPeriodEnd,
            accountPeriodStart,
            contractIds,
            dateBegin,
            dateEnd,
            entityIds,
            pageNumber,
            pageSize,
            tspId,
          },
        }) =>
          this._imbalanceOperationalBalancingAgreementService
            .getOBASummary(
              accountPeriodStart,
              accountPeriodEnd,
              pageSize,
              pageNumber,
              entityIds,
              contractIds,
              tspId,
              dateBegin,
              dateEnd
            )
            .pipe(
              map(
                (payload: OperationalBalancingAgmtCollection) => new FetchObaSummarySuccess(payload)
              ),
              catchError(error => of(new FetchObaSummaryFailure(error)))
            )
      )
    )
  );

  fetchImbalanceInventories$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchImbalanceInventories>(EImbalancesActions.FETCH_IMBALANCE_INVENTORIES),
      map((action: FetchImbalanceInventories) => action),
      switchMap(action => {
        const { accountingPeriod, accountIds } = action.payload;

        return this._imbalanceInventoryService
          .getInventory(
            !!accountingPeriod ? `${accountingPeriod.year}-${accountingPeriod.mon}-01` : null,
            1,
            999,
            null, //contractIds
            !!accountIds ? accountIds.join('+') : null
          )
          .pipe(
            map(
              (imbalanceInventoryCollection: InventoryCollection) =>
                new FetchImbalanceInventoriesSuccess({
                  imbalanceInventoryCollection: imbalanceInventoryCollection,
                })
            ),
            catchError(error => of(new FetchImbalanceInventoriesError({ error: error })))
          );
      })
    )
  );

  fetchAcctImbalance$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAcctImbalance>(EImbalancesActions.FETCH_ACCT_IMBALANCE),
      map((action: FetchAcctImbalance) => action),
      switchMap(action => {
        const { accountingPeriod, accountId } = action.payload;

        return this._imbalanceInventoryService
          .getAccountImbalance(
            !!accountingPeriod ? `${accountingPeriod.year}-${accountingPeriod.mon}-01` : null,
            accountId
          )
          .pipe(
            map(
              (acctImbalanceCollection: InventoryCollection) =>
                new FetchAcctImbalanceSuccess({
                  acctImbalanceCollection: acctImbalanceCollection,
                })
            ),
            catchError(error => of(new FetchAcctImbalanceError({ error: error })))
          );
      })
    )
  );

  fetchImbalanceTradeWindow$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTradeWindow>(EImbalancesActions.FETCH_TRADE_WINDOW),
      map((action: FetchTradeWindow) => action),
      switchMap(action => {
        const { tspId, accountPeriod, tradeDate } = action.payload;

        return this._imbalanceTradeWindow.tradeWindowStatus(tspId, accountPeriod, tradeDate).pipe(
          map(
            (tradeWindow: TradeWindow) => new FetchTradeWindowSuccess(tradeWindow.tradeWindowStatus)
          ),
          catchError(error => of(new FetchTradeWindowFailure(error)))
        );
      })
    )
  );

  fetchPipelinePosition$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPipelinePosition>(EImbalancesActions.FETCH_PIPELINE_POSITION),
      map((action: FetchPipelinePosition) => action),
      switchMap(action => {
        const { accountPeriod, tspId } = action.payload;

        return this._imbalancePipelinePosition
          .getImbalancePipelinePosition(tspId, accountPeriod)
          .pipe(
            map(
              (position: ImbalancePipelinePosition) => new FetchPipelinePositionSuccess(position)
            ),
            catchError(error => of(new FetchPipelinePositionFailure(error)))
          );
      })
    )
  );

  fetchMasterRateCICOScheduleCollection$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchMasterRateCICOScheduleCollection>(
      EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION
    ),
    map((action: FetchMasterRateCICOScheduleCollection) => action),
    switchMap(() =>
      this._masterRateCicoSchedulesService.getMasterRateCicoSchedules().pipe(
        map(
          (collection: MasterRateCICOScheduleCollection) =>
            new FetchMasterRateCICOScheduleCollectionSuccess(collection)
        ),
        catchError((error: HttpErrorResponse) =>
          of(new FetchMasterRateCICOScheduleCollectionFailure(error))
        )
      )
    )
  ));
}
